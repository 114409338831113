export interface EmployeeExportExcelReq {
  heading: string;
  data: any;
  headers: any;
}

export interface Header {
  key: string;
  value: string;
}

export const employeeHeadingVal: string = "Employees";

export const employeeHeadersObj: any = {
  employeeIdentificationString: "Employee ID",
  employeeName: "Name",
  employeeDesignation: "Designation",
  department: "Department",
  email: "Email",
  isLoginProvided: "Credentials Generated",
  dateOfJoin: "Date of Joining",
  grade: "Grade",
  employmentStatus: "Status",
  loginAccess: "Login Access",
  noOfGrants: "No. of Grants",
  employeeLoginStatus: "Last login",
};
export const employeeHeaderslist: string[] = [
  "employeeIdentificationString",
  "employeeName",
  "employeeDesignation",
  "department",
  "email",
  "isLoginProvided",
  "dateOfJoin",
  "grade",
  "employmentStatus",
  "loginAccess",
  "noOfGrants",
  "employeeLoginStatus",
];

export interface EmployeeDataSync {
  id: string;
  companyId: string;
  masterDataType: string;
  createdAt: string;
  updatedAt: string;
  name?: string;
  createdBy: any;
  updatedBy: any;
  employeeDataSyncHistories: EmployeeDataSyncHistory[];
}

export interface EmployeeDataSyncHistory {
  id: string;
  companyId: string;
  syncType: string;
  syncStatus: string;
  note?: string;
  employeeDataSyncId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: any;
  updatedBy: any;
}

export interface ConfigDetails {
  id: string;
  companyId: string;
  credentialData: CredentialData;
  mapperData: MapperData;
  masterDataType: string;
  settings: Settings;
  isEnabled: boolean;
  syncMode: "MANUAL" | "AUTOMATIC";
  createdAt: string;
  updatedAt: string;
  name: string | null;
}

export interface CredentialData {
  apiKey: string;
  datasetKey: string;
  domain: string;
  password: string;
  username: string;
}

export interface MapperData {
  hrId: FieldMapping;
  name: FieldMapping;
  type: FieldMapping;
  email: FieldMapping;
  grade: FieldMapping;
  status: FieldMapping;
  address: ComputedFieldMapping;
  contact: FieldMapping;
  lastDay: FieldMapping;
  manager: FieldMapping;
  legalName: ComputedFieldMapping;
  department: FieldMapping;
  grantValue: GrantFieldMapping;
  designation: FieldMapping;
  nationality: FieldMapping;
  userUniqueId: FieldMapping;
  dateOfJoining: FieldMapping;
  personalEmail: FieldMapping;
  resignationDate: FieldMapping;
  employeeIdentification: FieldMapping;
}

export interface FieldMapping {
  type: string;
  field: string;
}
export interface ComputedFieldMapping {
  type: string;
  template: string;
}
export interface GrantFieldMapping {
  type: string;
  field: string;
  valueType: string;
}
export interface Settings {
  syncFrequency: number;
  dataToSync: string[];
  considerResignationDateForLapsing: boolean;
  autoTransferToPersonalEmail: boolean;
  valuationForGrant: string;
  additionalSettings: Record<string, unknown>;
  syncMode: "MANUAL" | "AUTOMATIC" | "";
}
export interface MasterSettings {
  planFilter?: {
    planType?: {
      include: string[];
      exclude: string[];
    };
    planId?: {
      include: string[];
      exclude: string[];
    };
  };
  employeeFilter?: {
    department?: {
      include: string[];
      exclude: string[];
    };
    employMentType?: {
      include: string[];
      exclude: string[];
    };
    employmentStatus?: {
      include: string[];
      exclude: string[];
    };
  };
  subsidiaryFilter?: {
    include: string[];
    exclude: string[];
  };
  grantFilter?: {
    grantType?: {
      include: string[];
      exclude: string[];
    };
    grantState?: {
      include: string[];
      exclude: string[];
    };
  };
}
export interface NewConfigSettings extends MasterSettings {
  addingNewConfig?: boolean;
  type?: MasterApiType;
  enabled?: boolean;
  name?: string;
  configId?: string;
  credentialData?: { apiKey: string; datasetKey: string };
}
export type MasterApiType = "EMPLOYEE" | "GRANT";
export enum MasterApiData {
  EMPLOYEE = "EMPLOYEE",
  GRANT = "GRANT",
}
