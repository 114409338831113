/* eslint-disable max-len */
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Icon } from "@iconify/react";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { InputWithLabel, TextArea } from "../../components/shared/InputField";
import { useGetFaqSettings, useSaveFaqSettings } from "../../queries/faq";
import { Faq, FaqSettingInterface } from "../../types/faqPara";
import { determineUserAccessToResource } from "../../utils/auth";
import {
  Action,
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { usePermissionStore } from "../../store/permissionStore";
import { ErrorInterface } from "../../types/OptionBuyback";
import useIsMobile from "../../utils/detectDevice";

const FaqComponent = () => {
  const {
    data: _faqData,
    isFetched: isFaqsFetched,
    isLoading,
  } = useGetFaqSettings();
  const faqData = _faqData as FaqSettingInterface;
  const { isMobile } = useIsMobile();
  const { permission } = usePermissionStore();
  const [showFaqToggle, setshowFaqToggle] = useState(false);
  const { mutate: saveFaqs, isLoading: isFaqSaved } = useSaveFaqSettings();

  const [newFaq, setNewFaq] = useState<Faq>({
    question: "",
    answer: "",
  });
  const [adminFaqs, setAdminFaqs] = useState<any[]>([]);
  const [editActive, setEditActive] = useState(false);
  const [editIndex, setEditIndex] = useState<string>("0");
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [initialFaqEnabled, setInitialFaqEnabled] = useState(false);
  const [hasToggleChanged, setHasToggleChanged] = useState(false);

  useEffect(() => {
    if (faqData) {
      setAdminFaqs(faqData?.faqSettings);
      setshowFaqToggle(faqData.isFaqEnabled);
      setInitialFaqEnabled(faqData.isFaqEnabled);
      setHasToggleChanged(false);
    }
  }, [faqData]);

  const userCanActOnFaqs = useMemo(
    () =>
      determineUserAccessToResource(
        permission?.aclList || [],
        Resource.CompanySettings,
        Action.Edit
      ),
    [permission]
  );
  const [toBeDeleted, setToBeDeleted] = useState<Faq[]>([]);
  function deleteFaq(item: Faq, index: number) {
    const a = adminFaqs?.filter((faq: Faq, findex: number) => index !== findex);
    setToBeDeleted([...a]);
  }
  useEffect(() => {
    if (toBeDeleted.length > 0 || adminFaqs.length > 0) {
      setAdminFaqs([...toBeDeleted]);
    }
  }, [toBeDeleted]);

  function areFaqsUnchanged(
    originalFaqs: Faq[],
    updatedFaqs: Faq[],
    originalToggle: boolean,
    updatedToggle: boolean
  ): boolean {
    if (originalFaqs.length !== updatedFaqs.length) return false;
    const faqsUnchanged = originalFaqs.every(
      (faq, index) =>
        faq.question === updatedFaqs[index].question &&
        faq.answer === updatedFaqs[index].answer
    );
    const toggleUnchanged = originalToggle === updatedToggle;
    return faqsUnchanged && toggleUnchanged;
  }

  function handleSubmit() {
    if (hasPendingChanges) {
      toast("There are pending changes that need confirmation before saving.", {
        type: "warning",
        autoClose: 2000,
      });
      return;
    }
    const faqDto = adminFaqs?.filter(
      (faq: Faq) => faq.answer !== "" && faq.question !== ""
    );
    const faqObjectToSave: FaqSettingInterface = {
      isFaqEnabled: showFaqToggle,
      faqSettings: faqDto || [],
    };
    saveFaqs(faqObjectToSave, {
      onSuccess: () => {
        toast("FAQ saved Successfully!", { type: "success" });
        setHasPendingChanges(false);
        setInitialFaqEnabled(showFaqToggle);
        setHasToggleChanged(false);
      },
      onError: (err: any) => {
        const error = err.response.data as unknown as ErrorInterface;
        toast(err.message, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }
  function addFaq() {
    setNewFaq({ question: "", answer: "" });
    setAdminFaqs([...adminFaqs, newFaq]);
    setEditActive(true);
    setEditIndex(adminFaqs.length.toString());
    setHasPendingChanges(true);
  }

  function addToOriginalFaq(type: string) {
    if (type === "add") {
      setAdminFaqs([...adminFaqs, newFaq]);
    }
    if (type === "edit") {
      adminFaqs?.forEach((faq: Faq, index: number) => {
        if (index === parseInt(editIndex, 10)) {
          faq.question = newFaq?.question;
          faq.answer = newFaq?.answer;
        }
      });
      setAdminFaqs([...adminFaqs]);
    }
    setEditActive(false);
    setHasPendingChanges(false);
  }
  function editFaq(item: Faq, index: number) {
    setEditIndex(index.toString());
    setEditActive(true);
    setNewFaq({ question: item.question, answer: item.answer });
    setHasPendingChanges(true);
  }
  return (
    <>
      <VStack className="gap-4">
        <div className="flex items-baseline justify-between">
          <BCHeader className="items-baseline " bcTitle="FAQ Settings" />
        </div>

        <Box className="flex flex-col gap-4 px-8rounded-lg  ">
          <HStack className="items-center justify-between py-4">
            <h4
              className={`${
                isMobile ? "text-sm1" : "text-lg"
              } font-medium text-gray-dark`}
            >
              Frequently asked questions
            </h4>
            <div className="flex items-center justify-end gap-4">
              <SwitchButton
                className={`${
                  showFaqToggle ? "cursor-default " : ""
                } items-center`}
                value={showFaqToggle}
                label={"Show Faq to Employee"}
                onClick={() => {
                  if (userCanActOnFaqs) {
                    setshowFaqToggle(!showFaqToggle);
                    setHasToggleChanged(true);
                  }
                }}
              />
            </div>
          </HStack>
          {(adminFaqs || []).length === 0 && <EmptyFaqPage />}
          <Box className="flex flex-col gap-4">
            {adminFaqs &&
              adminFaqs?.map((faqItem: Faq, index: number) => (
                <VStack
                  key={faqItem?.answer}
                  className="px-6 py-4 bg-white-100 rounded-lg  bg-white border border-borderColor drop-shadow-box "
                >
                  <HStack className="w-full gap-2  ">
                    <Box>{index + 1}.</Box>
                    <VStack className="w-full gap-4">
                      <VStack>
                        {!editActive ||
                        index !== parseInt(editIndex || "0", 10) ? (
                          <div>{faqItem?.question}</div>
                        ) : (
                          <InputWithLabel
                            label={"Question"}
                            value={newFaq?.question}
                            onChange={(e) =>
                              setNewFaq({ ...newFaq, question: e.target.value })
                            }
                          />
                        )}
                      </VStack>
                      <VStack className="w-full p-4 border border-solid rounded border-red">
                        {!editActive || index !== parseInt(editIndex, 10) ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: faqItem?.answer,
                            }}
                          ></div>
                        ) : (
                          <InputWithLabel
                            label={"Answer"}
                            value={newFaq?.answer}
                            onChange={(e) =>
                              setNewFaq({ ...newFaq, answer: e.target.value })
                            }
                          />
                        )}
                      </VStack>
                      {!editActive || index !== parseInt(editIndex, 10) ? (
                        <HStack className="justify-end gap-2">
                          <HStack
                            className="flex-row-reverse"
                            onClick={() => deleteFaq(faqItem, index)}
                          >
                            <Icon
                              icon={"mdi:delete-circle-outline"}
                              width={24}
                              className="text-orange-501"
                            />
                          </HStack>
                          <HStack
                            className="flex-row-reverse"
                            onClick={() => editFaq(faqItem, index)}
                          >
                            <Icon
                              icon={"fluent-mdl2:edit-note"}
                              width={24}
                              className="text-orange-501"
                            />
                          </HStack>
                        </HStack>
                      ) : (
                        <HStack className="justify-end gap-2">
                          <HStack
                            className="flex-row-reverse"
                            onClick={() => addToOriginalFaq("edit")}
                          >
                            <Icon
                              icon={"fluent-mdl2:add-to"}
                              width={24}
                              className="text-orange-501"
                            />
                          </HStack>
                          <HStack
                            className="flex-row-reverse"
                            onClick={() => {
                              setNewFaq({ question: "", answer: "" });
                              setEditActive(false);
                            }}
                          >
                            <Icon
                              icon={"material-symbols:cancel"}
                              width={24}
                              className="text-orange-501"
                            />
                          </HStack>
                        </HStack>
                      )}
                    </VStack>
                  </HStack>
                </VStack>
              ))}
          </Box>
          {userCanActOnFaqs && (
            <div className="flex justify-end mt-5 mb-8">
              <ButtonSecondary
                className={`${
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.CompanySettings,
                    DefaultAction.All
                  )
                    ? "opacity-50 cursor-not-allowed flex flex-row mr-4"
                    : "flex flex-row mr-4"
                } items-center`}
                disabled={
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.CompanySettings,
                    DefaultAction.All
                  )
                }
                onClick={() => addFaq()}
              >
                +Add FAQ
              </ButtonSecondary>
              <ButtonPrimary
                type="submit"
                className={
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.CompanySettings,
                    DefaultAction.All
                  )
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }
                disabled={
                  !determineUserAccessToResource(
                    permission?.aclList || [],
                    Resource.CompanySettings,
                    DefaultAction.All
                  )
                }
                onClick={() => handleSubmit()}
                loading={isFaqSaved}
              >
                Save
              </ButtonPrimary>
            </div>
          )}
        </Box>
      </VStack>
    </>
  );
};

function EmptyFaqPage() {
  return (
    <VStack className="items-center">
      <div className="mx-auto">
        <img
          src="https://equion-dev.s3.us-west-2.amazonaws.com/assets/emptyTable.png"
          alt="No FAQs Found"
          height={"300px"}
          width={"300px"}
        />
      </div>
      <p className="">No FAQs found!! Start by adding some</p>
    </VStack>
  );
}

export default FaqComponent;
