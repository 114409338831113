import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMemo, useState } from "react";
import * as Yup from "yup";
import { Input, Label } from "../components/shared/InputField";
import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../components/utils";
import { getDefaultResignationProcessObject } from "../pages/employeeDetails/Resignation";
import {
  useGetResignationDetails,
  useResignationProcess,
} from "../queries/employees";
import { useError } from "../store/errorStore";
import { useGrants } from "../queries";

import { SwitchButton } from "../components/shared/SwitchButton";
import { useGetGrantLetterSettings } from "../queries/siteSettings";

type InitiateResignationType = {
  onClose: () => void;
  onPrimaryAction: () => void;
};

interface InitiateResignationValues {
  resignationDate: string;
}

export function InitiateResignation(props: InitiateResignationType) {
  const { id } = useParams();
  const _id = id ?? "";
  const { onClose, onPrimaryAction } = props;

  const resignationDto = getDefaultResignationProcessObject(_id);
  const { data: grantSettings } = useGetGrantLetterSettings();
  const { data: grantsData } = useGrants();
  const grantsForEmployee = grantsData?.filter(
    (grant) => grant.employeeId === _id
  );
  const hasNonGrantedGrants = grantsForEmployee?.some(
    (grant) =>
      grant.optionHolderState !== "GRANTED" &&
      grant.optionHolderState !== "RESIGNED"
  );
  const willgrantsBePasuedOnResignation = useMemo(
    () => grantSettings?.autoPauseVestingOnResignation,
    [grantSettings]
  );

  const errorMessage = useError();

  const { data: resignationDetails, refetch: getResignationDetails } =
    useGetResignationDetails(_id);
  const intialValues = {
    resignationDate:
      resignationDetails?.initiationDetails.resignationDate || "",
  };
  const [toggleDeleteGrants, setToggleDeleteGrants] = useState(false);

  const { mutate: resignationProcess } = useResignationProcess();

  const validationSchema = Yup.object().shape({
    resignationDate: Yup.string().required("Resignation Date is required"),
  });

  const handleSubmit = (values: InitiateResignationValues) => {
    resignationDto.initiationDetails.employeeId = _id;
    resignationDto.initiationDetails.resignationDate = values.resignationDate;
    resignationDto.initiationDetails.saveDetails = true;

    resignationProcess(resignationDto, {
      onSuccess: () => {
        toast("Initiated Resignation", {
          type: "success",
          autoClose: 2000,
        });
        getResignationDetails();
        onPrimaryAction();
      },
      onError: (err: any) => {
        const errMsg = err.response?.data?.errorsValidation?.errorRaw;
        if (errMsg) {
          errorMessage.setMessage(errMsg);
          toast(errMsg, {
            type: "error",
            autoClose: 2000,
          });
        }
      },
    });
  };

  return (
    <>
      <div className="">
        <div className="px-10 text-lg font-medium border-b py-7">
          <h6 className="flex justify-between">
            Initiate Resignation{" "}
            <span onClick={() => props.onClose()} className="cursor-pointer">
              X
            </span>{" "}
          </h6>
        </div>
        <VStack className="w-full px-10 py-5 gap-9">
          <Formik
            initialValues={intialValues}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => (
              <Form>
                <HStack className="flex-wrap">
                  <div className="py-4">
                    You are starting the resignation process for Employee,
                    please enter the resignation date to continue.
                  </div>
                  <HStack className="items-center">
                    <Label className="mr-10 text-sm font-normal whitespace-nowrap">
                      Resignation Date
                    </Label>
                    <VStack className="gap-1">
                      <Input
                        type="date"
                        {...formik.getFieldProps("resignationDate")}
                      />
                      {formik.touched.resignationDate &&
                        formik.errors.resignationDate && (
                          <Error text={formik.errors.resignationDate} />
                        )}
                    </VStack>
                  </HStack>
                </HStack>
                {willgrantsBePasuedOnResignation && (
                  <HStack className="text-gray-500 text-xs italic">
                    Note: Vestings will be paused for all the grants on the
                    resignation date.
                  </HStack>
                )}
                {hasNonGrantedGrants && (
                  <HStack>
                    <Label className="mt-4 text-sm  mr-2 font-medium">
                      Some grants are not in the GRANTED state. Do you want to
                      proceed with deleting them?
                    </Label>
                    <SwitchButton
                      className="mt-4 mb-2 mr-4 text-sm font-medium"
                      value={toggleDeleteGrants}
                      label=""
                      onClick={() => setToggleDeleteGrants(!toggleDeleteGrants)}
                    />
                  </HStack>
                )}
                <HStack className="justify-between mt-4">
                  <ButtonPrimary1 onClick={onClose}>Back</ButtonPrimary1>
                  <ButtonPrimary
                    type="submit"
                    disabled={hasNonGrantedGrants && !toggleDeleteGrants}
                  >
                    Next
                  </ButtonPrimary>
                </HStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </div>
    </>
  );
}
