/* eslint-disable max-len */
import { Icon } from "@iconify/react";
import { Dialog } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import _ from "lodash";
import { format } from "date-fns";
import FileInput from "../../components/shared/FileInput";
import { Input, Label } from "../../components/shared/InputField";
import { SwitchButton } from "../../components/shared/SwitchButton";
import Tooltip from "../../components/shared/Tooltip";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  HStack,
  VStack,
  isEsopViewer,
} from "../../components/utils";
import {
  useDeleteDefaultSignature,
  useGetGrantLetterSettings,
  useSetGrantLetterSettings,
} from "../../queries/siteSettings";

import { usePermissionStore } from "../../store/permissionStore";
import { GrantLetterSetting } from "../../types/SiteSettings";
import { downloadBlobObject } from "../../utils/DownloadFile";
import { determineUserAccessToResource } from "../../utils/auth";
import convertToBase64 from "../../utils/convertToBase64";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import { Select } from "../../components/shared/Select";

export interface FileTypeModel {
  fileBlob: string;
  fileName?: string;
  fileType?: string;
}
interface DateFormatSelectorProps {
  initialOrder: string[];
  separator?: string;
  onChange: (order: string[]) => void;
}

function GrantLetterSettings() {
  const {
    data: _grantLetterSettingDetails,
    refetch,
    isError,
    isFetched,
  } = useGetGrantLetterSettings();

  const initialValues: GrantLetterSetting = {
    acceptanceNumber: _grantLetterSettingDetails?.acceptanceNumber || 0,
    grantLetterTemplates:
      _grantLetterSettingDetails?.grantLetterTemplates || [],
    employeeSignatureForGrant:
      _grantLetterSettingDetails?.employeeSignatureForGrant || false,
    requestApproverSignature:
      _grantLetterSettingDetails?.requestApproverSignature || false,
    overrideGrantLetter:
      _grantLetterSettingDetails?.overrideGrantLetter || false,
    authorizedSignature: _grantLetterSettingDetails?.authorizedSignature,
    dateForLapsing:
      _grantLetterSettingDetails?.dateForLapsing || "lastWorkingDay",
    autoPauseVestingOnResignation:
      _grantLetterSettingDetails?.autoPauseVestingOnResignation || false,
  };
  const grantLetterSettingDetails = useMemo(
    () => _grantLetterSettingDetails,
    [_grantLetterSettingDetails]
  );

  const initialSettingsSet = useRef(false);
  const [initialSettings, setInitialSettings] = useState<GrantLetterSetting>(
    grantLetterSettingDetails!
  );
  const lapsingDateOptions = [
    { label: "Resignation Date", value: "resignationDate" },
    { label: "Last working Day", value: "lastWorkingDay" },
  ];
  const [separator, setSeparator] = useState("/");
  useEffect(() => {
    if (grantLetterSettingDetails) {
      formik.setValues(grantLetterSettingDetails);
    }
    if (!initialSettingsSet.current) {
      setInitialSettings(_.cloneDeep(grantLetterSettingDetails!));
      initialSettingsSet.current = true;
    }
  }, [grantLetterSettingDetails]);

  const { permission } = usePermissionStore();

  const validationSchema = Yup.object({
    acceptanceNumber: Yup.number()
      .positive("required")
      .typeError("expected a number"),
    employeeSignatureForGrant: Yup.boolean(),
    requestApproverSignature: Yup.boolean(),
  });

  const isUserEsopViewer = isEsopViewer();
  const { mutate: SetGrantLetterSettings, isLoading: isSettingsSaved } =
    useSetGrantLetterSettings();
  function doPartialTouch() {
    formik.setFieldTouched("acceptanceNumber");
  }

  const handleSubmit = (values: GrantLetterSetting) => {
    const changedGrantLetters = values.grantLetterTemplates?.filter(
      (template) => template.isChanged || template.isDeleted
    );
    const changedValues: any = {};
    Object.keys(values).forEach((key) => {
      const originalKey: keyof GrantLetterSetting =
        key as keyof GrantLetterSetting;
      if (
        values[originalKey] !== initialValues[originalKey] &&
        key !== "grantLetterTemplates"
      ) {
        changedValues[originalKey] = values[originalKey];
      }
    });
    if (changedGrantLetters?.length) {
      changedValues.grantLetterTemplates = changedGrantLetters;
    }
    if (Object.keys(changedValues).length === 0) {
      toast("No changes made", {
        type: "error",
        autoClose: 2000,
      });
      return;
    }
    SetGrantLetterSettings(changedValues, {
      onSuccess: () => {
        toast("Saved Successfully!", {
          type: "success",
          autoClose: 2000,
        });
      },
      onError: (err: any) => {
        toast(err.response?.data?.errorRaw || "Error occurred", {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  function handleOnCancel(values: GrantLetterSetting) {
    values = initialValues;
  }
  const separators = [
    { label: "Slash", value: "/" },
    { label: "Dash", value: "-" },
    { label: "Period", value: "." },
    { label: "Comma", value: "," },
    { label: "Blank", value: " " },
  ];

  const formik = useFormik<GrantLetterSetting>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    onSubmit: (values) => {
      handleSubmit(values);
    },
    onReset: (values) => {
      handleOnCancel(values);
    },
  });

  const [handleChanges, setHandleChanges] = useState<boolean>();

  useEffect(() => {
    const hasChanges = _.isEqual(grantLetterSettingDetails, formik.values);

    setHandleChanges(hasChanges);
  }, [initialSettings, grantLetterSettingDetails, formik.values]);

  const [grantLetter, setGrantLetter] = useState(
    formik.values.grantLetterTemplates
  );

  const [imageDialog, setImageDialog] = useState<{
    open: boolean;
  }>({ open: false });

  const [fileToUpload, setFileTopUpload] = useState("");
  const [fileName, setFileName] = useState("Upload File");
  const isValidImageFileUploaded = (
    file: File,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    if (!validExtensions.includes(fileExtension)) {
      return false;
    }
    if (file.size / 1024 > 50) {
      e.target.files = null;
      e.target.value = "";
      toast(
        `Your uploaded image size is ${(file.size / 1024).toFixed(
          4
        )}KB, File size should be less than 50kb, please upload a different file`,
        {
          type: "error",
        }
      );
      return false;
    } else return true;
  };
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64 = (await convertToBase64(file)) as unknown as string;
    setFileTopUpload(base64);
    setFileName("File Uploaded");
    formik.setFieldValue("authorizedSignature", base64);
  }
  const downloadGrantLetterTemplate = async (
    s3Path: string,
    fileName = "GrantLetterTemplate.docx"
  ) => {
    const res = await fetch(s3Path);
    const blob = await res.blob();
    downloadBlobObject(blob, fileName);
  };
  const { mutate: deleteDefaultSignature, isLoading } =
    useDeleteDefaultSignature();
  function deleteSignature() {
    //
    deleteDefaultSignature(
      {},
      {
        onSuccess: () => {
          toast("Deleted Successfully", { autoClose: 2000, type: "success" });
        },
        onError: (err: any) => {
          toast("Error", { autoClose: 2000, type: "error" });
        },
      }
    );
  }
  return (
    <form key="GrantLetterSettings" onSubmit={formik.handleSubmit}>
      <Box className="lg:w-[900px] md:w-[425px] p-4 bg-white rounded-lg ">
        <VStack className="justify-between p-4 gap-9">
          <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
            Grant Settings
          </Box>
          <HStack className="gap-4">
            <p className={` form-label text-sm font-medium mt-4`}>
              Window for acceptance of Grant letter (in days)
            </p>
            <Input
              type="number"
              className="lg:w-48 md:w-32"
              {...formik.getFieldProps("acceptanceNumber")}
            />
          </HStack>
          <HStack className="justify-between">
            <HStack
              className={` form-label text-sm font-medium w-2/3 gap-4 items-center`}
            >
              Request Employees to Sign the Grant Letter
              <Tooltip
                text={`If Enabled, please make sure that Grant Letter Template has the placeholder "IMAGE insertImage(Image_EmployeeSignatory)" for Employee Signature `}
                _className="w-[400px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </HStack>
            <Box className="flex items-start w-1/3 gap-1 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "employeeSignatureForGrant",
                    !formik.values.employeeSignatureForGrant
                  );
                }}
                className=""
                value={formik.values.employeeSignatureForGrant}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <HStack
              className={` form-label text-sm font-medium w-2/3 gap-4 items-center`}
            >
              Request Approver Signature while approving a Grant
              <Tooltip
                text={`If Enabled, the approver would have to sign while approving the grant`}
                _className="w-[400px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </HStack>
            <Box className="flex items-start w-1/3 gap-1 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "requestApproverSignature",
                    !formik.values.requestApproverSignature
                  );
                }}
                className=""
                value={formik.values.requestApproverSignature}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={`inline-flex form-label text-sm font-medium gap-4 `}>
              Auto Pause Vesting on Resignation Date
              <Tooltip
                text={`Grant's Vestings will be paused on the resignation date`}
                _className="w-[400px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "autoPauseVestingOnResignation",
                    !formik.values.autoPauseVestingOnResignation
                  );
                }}
                className=""
                value={formik.values.autoPauseVestingOnResignation || false}
                label=""
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={`inline-flex form-label text-sm font-medium gap-4 `}>
              Date to use for Grant Lapsing and Forfeiture
              <Tooltip
                text={`Date to use for Grant Lapsing and Forfeiture post resignation`}
                _className="w-[400px] "
              >
                <Icon
                  icon="material-symbols:info-outline-rounded"
                  height={24}
                  width={24}
                  className="w-full text-xs font-medium rounded cursor-pointer text-slate-dark"
                />
              </Tooltip>
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <Select
                className="w-48"
                options={lapsingDateOptions}
                value={formik.values?.dateForLapsing}
                onChange={(event) => {
                  formik.setFieldValue("dateForLapsing", event.target.value);
                }}
                textGetter={(option) => option.label}
                valueGetter={(option) => option.value}
              />
            </Box>
          </HStack>
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium `}>
              Override default Grant letter template
            </p>
            <Box className="flex items-start w-1/3 ml-2">
              <SwitchButton
                onClick={() => {
                  formik.setFieldValue(
                    "overrideGrantLetter",
                    !formik.values.overrideGrantLetter
                  );
                }}
                className=""
                value={formik.values.overrideGrantLetter}
                label=""
              />
            </Box>
          </HStack>
          {formik.values.overrideGrantLetter && (
            <Box className="max-h-full overflow-auto h-[300px]">
              <table className="w-full table-space">
                <thead className="text-xs font-medium text-[#B5B5C3] ">
                  <tr className=" border-b border-[#E4E6EF] border-dashed">
                    <td className="py-3 hover:cursor-pointer">
                      <HStack className="ml-6">
                        PLAN<VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                    <td className="py-3 hover:cursor-pointer">
                      <HStack>
                        GRANT LETTER
                        <VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                    <td className="py-3 hover:cursor-pointer">
                      <HStack>
                        UPLOAD
                        <VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                    <td className="py-3 hover:cursor-pointer">
                      <HStack>
                        DELETE
                        <VStack className="h-4"></VStack>
                      </HStack>
                    </td>
                  </tr>
                </thead>
                <tbody className="font-medium text-dark">
                  {formik.values.grantLetterTemplates?.map(
                    (template, index) => (
                      <tr
                        key={index}
                        className=" border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50"
                      >
                        <td className="py-2">
                          <VStack>
                            <Box>
                              <Tooltip
                                text={
                                  formik.values.grantLetterTemplates[index]
                                    .planName
                                }
                                _className="  w-[200px]"
                              >
                                <Input
                                  id={index.toString()}
                                  type="text"
                                  className="w-36"
                                  disabled
                                  {...formik.getFieldProps(
                                    `grantLetterTemplates[${index}].planName`
                                  )}
                                />
                              </Tooltip>
                              <ActionIndicator
                                status={
                                  template.isAdded
                                    ? "ADDED"
                                    : template.isDeleted
                                    ? "DELETED"
                                    : template.isChanged
                                    ? "EDITED"
                                    : undefined
                                }
                              />
                            </Box>
                          </VStack>
                        </td>
                        <td className="py-2">
                          <Box className="w-32">
                            {formik.values.grantLetterTemplates[index]
                              .s3Path !== null &&
                              formik.values.grantLetterTemplates[index]
                                .s3Path !== "" && (
                                <div className="relative flex flex-col justify-between">
                                  <div
                                    className="mt-4 text-red-500 underline"
                                    onClick={() => {
                                      if (
                                        formik.values.grantLetterTemplates[
                                          index
                                        ].s3Path !== null ||
                                        formik.values.grantLetterTemplates[
                                          index
                                        ].s3Path !== undefined
                                      ) {
                                        downloadGrantLetterTemplate(
                                          formik.values.grantLetterTemplates[
                                            index
                                          ].s3Path || "",
                                          `${template.planName}-GrantLetterTemplate.docx`
                                        );
                                      }
                                    }}
                                  >
                                    Grant Letter
                                  </div>
                                </div>
                              )}
                          </Box>
                        </td>
                        <td className="py-2">
                          {!formik.values.grantLetterTemplates[index]
                            .isPlatFormDefault && (
                            <Box className="w-8 mt-4">
                              <label className="px-2 py-2 rounded-lg cursor-pointer ">
                                <Input
                                  type="file"
                                  className="hidden"
                                  accept=".docx"
                                  id={index.toString()}
                                  onChange={async (e) => {
                                    if (e?.target?.files?.[0]) {
                                      const file = e.target.files[0];
                                      const fileExtenstion =
                                        file.name.split(".")[
                                          file.name.split(".").length - 1
                                        ];
                                      const acceptedExtensions = ["docx"];
                                      if (
                                        acceptedExtensions.includes(
                                          fileExtenstion.toLowerCase()
                                        )
                                      ) {
                                        const base64: string =
                                          (await convertToBase64(
                                            file
                                          )) as string;
                                        formik.setFieldValue(
                                          `grantLetterTemplates[${index}].planLevelGrantTemplate`,
                                          base64
                                        );
                                        formik.setFieldValue(
                                          `grantLetterTemplates[${index}].isChanged`,
                                          true
                                        );
                                        if (
                                          !initialValues.grantLetterTemplates[
                                            index
                                          ].s3Path
                                        ) {
                                          formik.setFieldValue(
                                            `grantLetterTemplates[${index}].isAdded`,
                                            true
                                          );
                                        }
                                      } else {
                                        toast("File type not supported", {
                                          type: "error",
                                        });
                                      }
                                    }
                                  }}
                                />
                                <Icon
                                  icon="ic:baseline-file-upload"
                                  height={24}
                                />
                              </label>
                            </Box>
                          )}
                        </td>
                        <td className="py-2">
                          {!formik.values.grantLetterTemplates[index]
                            .isPlatFormDefault && (
                            <Box className="w-8 mt-4 ml-2">
                              <div
                                onClick={() => {
                                  if (
                                    formik.values.grantLetterTemplates[index]
                                      .s3Path !== null &&
                                    formik.values.grantLetterTemplates[index]
                                      .s3Path !== ""
                                  ) {
                                    formik.setFieldValue(
                                      `grantLetterTemplates[${index}].s3Path`,
                                      null
                                    );
                                    formik.setFieldValue(
                                      `grantLetterTemplates[${index}].isDeleted`,
                                      true
                                    );
                                  }
                                }}
                                className="text-zinc-300 hover:scale-105"
                              >
                                <Icon
                                  icon="mi:delete"
                                  color="#ff0000"
                                  height={24}
                                />
                              </div>
                            </Box>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Box>
          )}
          <HStack className="justify-between">
            <p className={` form-label text-sm font-medium  `}>
              Default authorized signature to be used on Grant letter
            </p>
            <HStack className="flex items-center justify-between w-1/3">
              {(fileName === "File Uploaded" ||
                !!_grantLetterSettingDetails?.authorizedSignature) && (
                <Box
                  className="underline cursor-pointer text-orange-501"
                  onClick={() => setImageDialog({ open: true })}
                >
                  Authorized Signature
                </Box>
              )}
              <FileInput
                file={fileName}
                key={fileToUpload}
                accept="image/png, image/jpeg,image/jpg"
                onChange={(e) => {
                  if (e?.target?.files?.[0]) {
                    const file = e.target.files[0];
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = async () => {
                      const width = img.width;
                      const height = img.height;
                      if (width > 240 || height > 120) {
                        toast(
                          "File height and width should be less than 120px and 240px",
                          {
                            type: "error",
                          }
                        );
                        formik.setFieldValue("authorizedSignature", "");
                        setFileTopUpload("");
                        setFileName("upload file");
                        e.target.files = null;
                        e.target.value = "";
                      } else if (isValidImageFileUploaded(file, e)) {
                        setFileName("File Uploaded");
                        const base64: string = (await convertToBase64(
                          file
                        )) as string;
                        setFileTopUpload(base64);
                        formik.setFieldValue("authorizedSignature", base64);
                      }
                    };
                  }
                }}
              />
              <Dialog
                open={imageDialog.open}
                onClose={() => setImageDialog({ open: false })}
                maxWidth="lg"
              >
                {
                  <img
                    className="image"
                    src={`${
                      fileName === "File Uploaded"
                        ? `data:image/png;base64,${formik.values.authorizedSignature}`
                        : formik.values.authorizedSignature
                    }`}
                    alt="no image"
                  />
                }
              </Dialog>
              {!!_grantLetterSettingDetails?.authorizedSignature && (
                <Icon
                  icon={"mdi:delete-empty"}
                  className="text-orange-501"
                  width={24}
                  onClick={() => deleteSignature()}
                />
              )}
            </HStack>
          </HStack>
        </VStack>
        {!isUserEsopViewer && (
          <HStack className="justify-between mt-12">
            <ButtonPrimary1 type="reset" className="mr-8 text-red-500">
              Cancel
            </ButtonPrimary1>
            <ButtonPrimary
              className={`${
                !handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
                  ? " cursor-not-allowed"
                  : ""
              } flex items-center self-end justify-center`}
              disabled={
                isError ||
                handleChanges ||
                !determineUserAccessToResource(
                  permission?.aclList || [],
                  Resource.CompanySettings,
                  DefaultAction.All
                )
              }
              type="submit"
              loading={isSettingsSaved}
            >
              Save Settings
            </ButtonPrimary>
          </HStack>
        )}
      </Box>
    </form>
  );
}

const ActionIndicator = (props: {
  status: "ADDED" | "EDITED" | "DELETED" | undefined;
}): React.ReactElement => {
  const status = props.status;
  const colourMapper = {
    ADDED: "text-green-400",
    EDITED: "text-blue-400",
    DELETED: "text-red-400",
  };
  return (
    <span className={`pl-0.5 text-xxs ${colourMapper[status || "EDITED"]}`}>
      {" "}
      {status && `will be ${status.toLowerCase()}`}
    </span>
  );
};
const DateFormatSelector: React.FC<DateFormatSelectorProps> = ({
  initialOrder,
  separator = "-",
  onChange,
}) => {
  const [formats, setFormats] = useState(initialOrder);
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
  useEffect(() => {
    setFormats(initialOrder);
  }, [initialOrder]);
  const availableFormats = {
    Day: ["d", "dd", "do"],
    Month: ["M", "Mo", "MM", "MMM", "MMMM"],
    Year: ["y", "yyy", "yyyy"],
  };

  const getLabel = (format: string) => {
    if (availableFormats.Day.includes(format)) return "Day";
    if (availableFormats.Month.includes(format)) return "Month";
    if (availableFormats.Year.includes(format)) return "Year";
    return "Unknown";
  };

  const getOptions = (format: string) => {
    const label = getLabel(format);
    if (label === "Unknown") return [];
    const availableValues = Array.from(new Set(availableFormats[label] || []));
    return availableValues;
  };

  const handleDragStart = (index: number) => {
    setDraggedItemIndex(index);
  };

  const handleDragOver = (index: number) => {
    if (draggedItemIndex === null || draggedItemIndex === index) return;

    const newFormats = [...formats];
    const draggedFormat = newFormats[draggedItemIndex];

    newFormats.splice(draggedItemIndex, 1);
    newFormats.splice(index, 0, draggedFormat);

    setFormats(newFormats);
    setDraggedItemIndex(index);

    onChange(newFormats);
  };

  const handleDragEnd = () => {
    setDraggedItemIndex(null);
  };

  const handleFormatChange = (index: number, newFormat: string) => {
    const newFormats = [...formats];
    newFormats[index] = newFormat;

    setFormats(newFormats);

    onChange(newFormats);
  };
  const getFlex = (label: string) => {
    const flex = "30%";
    if (label === "Month") return "40%";
    if (label === "Year") return "35%";
    return flex;
  };
  return (
    <div className="w-full">
      <div className="flex justify-between w-full">
        {formats.map((format, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => {
              e.preventDefault();
              handleDragOver(index);
            }}
            onDragEnd={handleDragEnd}
            className={`flex-1 m-1 p-1 text-center rounded-md cursor-move transition-all duration-200 
              ${draggedItemIndex === index ? "shadow-lg" : ""} `}
            style={{
              flexBasis: getFlex(getLabel(format)),
            }}
          >
            <p className="flex gap-2 items-center justify-center">
              {getLabel(format)}{" "}
              <Icon
                className="text-end inline-flex"
                height={12}
                width={12}
                icon={"carbon:draggable"}
              ></Icon>{" "}
            </p>
            <Select
              isPlaceHolderDisabled={true}
              placeholder={getLabel(format)}
              options={getOptions(format)}
              value={format}
              onChange={(e) => handleFormatChange(index, e.target.value)}
            />
          </div>
        ))}
      </div>
      <div
        className="mt-2 ml-1 text-blue-400 transition-transform duration-300 ease-in-out transform scale-105"
        key={formats.join(separator)}
      >
        Example: {format(new Date(), formats.join(separator))}
      </div>
      {formats.join(separator)}
    </div>
  );
};

export default GrantLetterSettings;
