/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getYear } from "date-fns";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import AvatarEditor from "react-avatar-editor";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";
import {
  CheckBoxWithLabel,
  Input,
  Label,
} from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Error,
  HStack,
  VStack,
  getCompanyName,
} from "../../components/utils";
import {
  useDownloadEsopAccounting,
  useEmployees,
  useEmployeesDepartment,
  useEsopPlans,
  useSubsidiaryCompanyNames,
  useVerifyAccountingUploadTemplate,
} from "../../queries";
import { EsopAccountingReq, PlanState } from "../../types/EsopPlan";
import { ErrorInterface } from "../../types/OptionBuyback";
import {
  convertBase64ToBlob,
  downloadBlobObject,
  downloadS3File,
} from "../../utils/DownloadFile";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { MultiSelectV2 } from "../../components/shared/MultiSelectV2";
import { SubsidiaryCompanyDropDown } from "../../types/EsopSubsidiaryCompany";
import { OptionTypes } from "../../components/shared/MultiSelect";
import { EmployementType } from "../../types/Employee";
import { useValuationDetails } from "../../queries/Valuation";
import { formatDisplayDate } from "../../utils/date";
import { useCompanyStore } from "../../store";
import { getAccountingTemplate } from "../../api/Esop";
import convertToBase64 from "../../utils/convertToBase64";

type Step = {
  stepNo: number;
  title: string;
  description: string;
  children?: Step[];
};
type StepProps = {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
};

const grantStates: OptionTypes[] = [
  { value: "CREATED" },
  { value: "FOR_APPROVAL" },
  { value: "APPROVED" },
  { value: "OFFERED" },
  { value: "GRANTED" },
  { value: "WITHDRAW" },
  { value: "NOT_APPROVED" },
];

const EsopAccountingDialog = ({
  onClose = () => {},
}: {
  onClose: () => void;
}) => {
  const { companyData } = useCompanyStore();
  const currentDate = new Date();
  const initialValues = {
    attritionValue: 0,
    hasSelectedIGAAP: false,
    hasSelectedFairMarketValue: false,
    hasSelectedGradedMethod: false,
    changeValuationPerVesting: false,
    planType: "sar",
    startDate: `${getYear(currentDate)}-04-01`,
    endDate: `${getYear(currentDate) + 1}-03-31`,
    planIds: [""],
    grantStates: [],
    companyIds: [""],
    departments: [],
    shareHolderType: [],
  };
  const { mutate: verifyTemplateData, isLoading: verifyingData } =
    useVerifyAccountingUploadTemplate();
  const [accountingStandard, setAccountingStandard] = useState("IND-AS");
  const [accountingMethod, setAccountingMethod] = useState("Graded");
  const [valuationType, setValuationType] = useState("FMV");
  const [planType, setPlanType] = useState("ALL");
  const [methods, setMethods] = useState(["Graded"]);
  const [currentStep, setCurrentStep] = useState(1);
  const [existingValuations, setExistingValuations] = useState(false);
  const [allValuations, setAllValuations] =
    useState<{ valuationDate: string; valuationAmount: number }[]>();
  const { data: _valuations, isFetched } = useValuationDetails();

  const downloadTemplate = () => {
    getAccountingTemplate().then((uploadTemplate) => {
      const template = uploadTemplate.data.uploadTemplate;
      convertBase64ToBlob(
        template,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ).then((blobObject) => {
        downloadBlobObject(
          blobObject,
          `Multiple_valuations_ESOP_accounting_template.xlsx`
        );
      });
    });
  };

  useEffect(() => {
    if (_valuations && _valuations.length > 0) {
      setAllValuations([
        ..._valuations.map((s) => ({
          valuationDate: formatDisplayDate(s.dateOfValuation),
          valuationAmount: s.pps,
        })),
      ]);
    }
  }, [isFetched]);
  const { data: _plans } = useEsopPlans();
  let plans = _plans || [];
  const [shouldPiiBeMasked, setShouldPiiBeMasked] = useState(false);
  const [planNames, setPlanNames] = useState<OptionTypes[]>([]);
  const [file, setFile] = useState<File>();
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (_plans && _plans.length > 0) {
      plans = _plans;
      setPlanNames([..._plans.map((s) => ({ value: s.planName }))]);
    }
  }, [_plans]);
  const [changeValuationPerVesting, setChangeValuationPerVesting] =
    useState("Grant Date");
  const [subsidiaryNames, setSubsidiaryNames] = useState<OptionTypes[]>([]);
  const [subsidiaryList, setSubsidiaryList] = useState<
    SubsidiaryCompanyDropDown[]
  >([]);
  const { data: subsidiaryCompanyNamesList } = useSubsidiaryCompanyNames();
  useEffect(() => {
    if (subsidiaryCompanyNamesList && companyData) {
      setSubsidiaryList([
        ...subsidiaryCompanyNamesList,
        { id: companyData.id, name: companyData.name },
      ]);
      setSubsidiaryNames([
        ...subsidiaryCompanyNamesList.map((s) => ({ value: s.name })),
        { value: companyData.name },
      ]);
    }
  }, [subsidiaryCompanyNamesList, companyData]);
  const [departments, setDepartments] = useState<OptionTypes[]>([]);
  const { data: employees } = useEmployeesDepartment();
  useEffect(() => {
    if (employees && employees.departmentEmployeeCountDtos.length > 0) {
      setDepartments([
        ...employees.departmentEmployeeCountDtos.map((s) => ({
          value: s.department,
        })),
      ]);
    }
  }, [employees]);
  const esopAccountingFileName = `ESOP_Accounting_${getCompanyName()}.xlsx`;
  const { mutate: downloadEsopAccounting } = useDownloadEsopAccounting();
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .when("startDate", (startDate, schema) =>
        startDate
          ? schema.min(startDate, "End date must be greater than start date")
          : schema
      ),
  });
  const dateTypes = [
    "Current Financial Year",
    "Previous Financial Year",
    "Custom Interval",
  ];
  function onStepChange(step?: number) {
    setCurrentStep(step || parseInt(`${currentStep}`, 10) + 1);
  }
  function onBackClick() {
    if (currentStep !== 1) {
      setCurrentStep(parseInt(`${currentStep}`, 10) - 1);
    }
  }
  async function handleNextClick() {
    if (currentStep === 3) {
      if (disableDownload()) return;
      setErrors([]);
      const reqValues: EsopAccountingReq = {
        attritionValue:
          valuationType === "Intrinsic" ? 0 : formik.values.attritionValue,
        hasSelectedFairMarketValue: valuationType === "FMV",
        hasSelectedGradedMethod: accountingMethod === "Graded",
        startDate: new Date(formik.values.startDate),
        endDate: new Date(formik.values.endDate),
        planType: planType.toLowerCase(),
        changeValuationPerVesting: changeValuationPerVesting === "Vesting Date",
        planIds: formik.values.planIds,
        shouldPiiBeMasked,
        isFileUploaded: !existingValuations,
        customValuationExcelFile: existingValuations
          ? undefined
          : file && ((await convertToBase64(file)) as string),
        grantStates: formik.values.grantStates,
        companyIds: formik.values.companyIds,
        employmentType: formik.values.shareHolderType,
        departments: formik.values.departments,
      };
      if (file && !existingValuations) {
        verifyTemplate(file);
        if (errors.length > 0) return;
      }
      downloadEsopAccounting(reqValues, {
        async onSuccess(data) {
          const base64File = data?.data?.base64File || "";
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = await convertBase64ToBlob(base64File, fileType);
          downloadBlobObject(blob, esopAccountingFileName);
          onClose();
        },
        onError(err: any) {
          const error = err.response.data as ErrorInterface;
          toast(error.errors, { type: "error", autoClose: 5000 });
          onClose();
        },
      });
    } else {
      onStepChange();
    }
  }

  function handleBackClick() {
    if (currentStep === 1) {
      onClose();
    } else {
      onBackClick();
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {},
  });
  const [selectedDateType, setSelectedDateType] = useState("");

  const handleDateTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setSelectedDateType(e.target.value);
      const date = new Date();
      switch (e.target.value) {
        case "As of Date":
          formik.setFieldValue("startDate", "");
          break;
        case "Current Financial Year":
          formik.setFieldValue("startDate", `${date.getFullYear()}-04-01`);
          formik.setFieldValue("endDate", `${date.getFullYear() + 1}-03-31`);
          break;
        case "Previous Financial Year":
          formik.setFieldValue("startDate", `${date.getFullYear() - 1}-04-01`);
          formik.setFieldValue("endDate", `${date.getFullYear()}-03-31`);
          break;
        default:
          break;
      }
    }
  };

  const verifyTemplate = async (file: File) => {
    const base64File = (await convertToBase64(file)) as string;
    const reqValues: EsopAccountingReq = {
      attritionValue:
        valuationType === "Intrinsic" ? 0 : formik.values.attritionValue,
      hasSelectedFairMarketValue: valuationType === "FMV",
      hasSelectedGradedMethod: accountingMethod === "Graded",
      startDate: new Date(formik.values.startDate),
      endDate: new Date(formik.values.endDate),
      planType: planType.toLowerCase(),
      changeValuationPerVesting: changeValuationPerVesting === "Vesting Date",
      planIds: formik.values.planIds,
      shouldPiiBeMasked,
      isFileUploaded: true,
      customValuationExcelFile: base64File,
      grantStates: formik.values.grantStates,
      companyIds: formik.values.companyIds,
      employmentType: formik.values.shareHolderType,
      departments: formik.values.departments,
    };
    verifyTemplateData(reqValues, {
      onSuccess: (data: any) => {
        if (Array.isArray(data.data) && data.data.length > 0) {
          const errorMessages = data.data.map(
            (row: any) =>
              `Valuation is not provided for identifier ${row[0].grantID}`
          );
          setErrors(errorMessages);
        }
      },
      onError: (data: any) => {
        const errors = data.response.data.errorRaw as string;
        const errorsArray = [];
        if (errors.includes(",")) errorsArray.push(...errors.split(","));
        else errorsArray.push(errors);

        setErrors(errorsArray);
      },
    });
  };

  const FileDropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop: async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
          const newFile = acceptedFiles[0];
          setFile(newFile);
          setErrors([]);
          verifyTemplate(newFile);
        }
      },
    });

    return (
      <div>
        <div className="mx-20">
          <div className="flex flex-col items-center justify-center  w-[100%] h-[110px] border-2 border-dashed border-[#0c224a] rounded-lg cursor-pointer bg-[#f6f9ff] hover:bg-gray-200 transition">
            <div
              {...getRootProps()}
              className="flex flex-col items-center justify-center w-full h-full"
            >
              <input {...getInputProps()} />
              <Icon
                icon="material-symbols:upload-sharp"
                color="#E85936"
                height={32}
              />
              <p className="text-sm text-gray-600">
                Drag and drop or{" "}
                <span className="text-[#0327e4]">
                  <u>browse</u>
                </span>{" "}
                your files
              </p>
              {file && (
                <HStack className="items-center gap-2 pt-2 text-center">
                  <p className="text-sm font-medium text-gray-700 ">
                    Uploaded: {file.name}
                  </p>
                  <Icon
                    icon={"mdi:trash"}
                    height={18}
                    width={18}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFile(undefined);
                      setErrors([]);
                    }}
                  />
                </HStack>
              )}
            </div>
          </div>
        </div>
        {errors.length > 0 && (
          <div className="p-2 mt-6 overflow-auto  border border-red-600 bg-[#c728280d] rounded max-h-48 ">
            <HStack className="gap-2">
              <Icon
                icon={"material-symbols:error"}
                color="#c72828"
                height={20}
              />
              <p className="font-medium text-red-600">
                {errors.length} Errors Found
              </p>
            </HStack>
            <ul className="text-red-600 list-disc ">
              {errors.map((err, index) => (
                <li className="pt-1 text-xs mx-14 " key={index}>
                  {err}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  const EsopAccountingStepper = (props: StepProps) => {
    const currentStep = useMemo(() => props.currentStep, [props.currentStep]);
    const StepContainer = (props: {
      step: Step;
      current?: boolean;
      completed?: boolean;
      nextStepsAvailable?: boolean;
      currentStep?: number;
      setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
      _className?: string;
    }) => {
      const {
        step,
        current,
        completed,
        currentStep,
        setCurrentStep,
        _className,
      } = props;
      function handleStepClick(
        step: Step,
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
      ): void {
        event.stopPropagation();
        setCurrentStep(step.stepNo);
      }
      return (
        <HStack
          className={`w-full cursor-pointer pt-7 ${
            !_className ? "pl-6" : _className
          }`}
        >
          <VStack
            className={`justify-between w-1/5`}
            onClick={(_) => handleStepClick(step, _)}
          >
            <div
              className={`rounded-md shadow-md  ${
                current
                  ? " text-white bg-orange-501  "
                  : "bg-opacity-50 text-orange-501"
              }  w-10 h-10 inline-flex justify-center items-center font-medium`}
            >
              {completed ? (
                <Icon icon="akar-icons:check" className="text-orange-501" />
              ) : (
                step.stepNo
              )}
            </div>
          </VStack>
          <VStack
            className={`w-4/5 justify-evenly`}
            onClick={(_) => handleStepClick(step, _)}
          >
            <div
              className={`text-sm2 font-medium text-black-501 ${
                current && "text-orange-501"
              } `}
            >
              {step.title}
            </div>
            <div
              className={`text-xs0 text-gray-401 font-medium`}
              onClick={(_) => handleStepClick(step, _)}
            >
              {step.description}
            </div>
          </VStack>
        </HStack>
      );
    };
    const steps = useMemo(() => {
      const returnData: Step[] = [
        {
          stepNo: 1,
          title: "ESOP Accounting Report",
          description: "Data characteristics for report",
        },
        {
          stepNo: 2,
          title: "Report Filters",
          description: "Apply suitable filters ",
        },
        {
          stepNo: 3,
          title: "Valuation Selection",
          description: "Choose valuation methods",
        },
      ];
      return returnData;
    }, []);
    return (
      <Box className="h-full">
        {steps.map((step) => (
          <StepContainer
            key={step.stepNo}
            step={step}
            completed={
              parseInt(`${currentStep}`, 10) > parseInt(`${step.stepNo}`, 10)
            }
            current={
              parseInt(`${currentStep}`, 10) === parseInt(`${step.stepNo}`, 10)
            }
            currentStep={currentStep}
            setCurrentStep={props.setCurrentStep}
          />
        ))}
      </Box>
    );
  };

  const disableDownload = () => {
    if (currentStep !== 3) return false;
    if (verifyingData) return true;
    if (!file && !existingValuations) return true;
    if (!existingValuations && errors.length > 0) return true;
    return false;
  };

  return (
    <HStack className=" flex w-[1000px] max-h-[620px] min-h-[620px] gap-3 bg-gray-100 ">
      <VStack className="flex w-[25%] bg-white rounded-md pt-10">
        <EsopAccountingStepper
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </VStack>
      <VStack className=" flex w-[75%]  bg-white rounded-md justify-start ">
        {currentStep === 1 && (
          <VStack className="justify-start h-full gap-2">
            <div className="p-6 text-lg font-semibold border-b">
              <div className="flex justify-between">ESOP Accounting Report</div>
              <div className="text-xs font-medium text-gray-400">
                Select the reporting period and data parameters to generate the
                ESOP Accounting Report.
              </div>
            </div>
            <div className="pt-6 pb-2 text-base font-medium border-b ">
              <div className="flex justify-between pl-6">Time Period</div>
            </div>
            <HStack className="gap-6 px-6 py-2">
              <div className="flex-1">
                <Label className="text-sm font-normal">Date Type</Label>
                <Select
                  options={dateTypes}
                  onChange={(e) => handleDateTypeChange(e)}
                  value={selectedDateType || "Current Financial Year"}
                />
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Start date</Label>
                <Input type="date" {...formik.getFieldProps("startDate")} />
                {formik.touched.startDate && formik.errors.startDate && (
                  <Error text={formik.errors.startDate} />
                )}
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">End Date</Label>
                <Input type="date" {...formik.getFieldProps("endDate")} />
                {formik.touched.endDate && formik.errors.endDate && (
                  <Error text={formik.errors.endDate} />
                )}
              </div>
            </HStack>
            <div className="py-2 text-base font-medium border-b ">
              <div className="flex justify-between pl-6">
                Data Characteristics
              </div>
            </div>
            <HStack className="gap-6 px-6 py-2">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Accounting Standards
                </Label>
                <Select
                  options={["IND-AS", "IGAAP"]}
                  value={accountingStandard}
                  onChange={(e) => {
                    setAccountingStandard(e.target.value);
                    if (e.target.value === "IND-AS") {
                      setAccountingMethod("Graded");
                      setMethods(["Graded"]);
                    } else {
                      setMethods(["Graded", "Straight"]);
                    }
                  }}
                ></Select>
              </div>
              <div className="flex-1">
                <Label className="text-sm font-normal">Accounting Method</Label>
                <Select
                  options={methods}
                  value={accountingMethod}
                  onChange={(e) => {
                    setAccountingMethod(e.target.value);
                  }}
                ></Select>
              </div>
            </HStack>
            <HStack className="gap-6 px-6 py-2">
              <div className="flex-1">
                <Label className="text-sm font-normal">
                  Attrition Value(%)
                </Label>
                <Input
                  type="number"
                  disabled={valuationType === "Intrinsic"}
                  {...formik.getFieldProps("attritionValue")}
                />
              </div>
              <div className="flex-1">
                <HStack className="gap-1">
                  <Label className="text-sm font-normal">
                    PII (Personal Identification Info.) Protection
                  </Label>
                  <SwitchButton
                    onClick={() => {
                      setShouldPiiBeMasked(!shouldPiiBeMasked);
                    }}
                    className=""
                    value={shouldPiiBeMasked}
                    label=""
                  />
                </HStack>
                <p className="font-medium text-gray-400 text-xxs">
                  Enabling this would censor all the names and other personal
                  information from the reports
                </p>
              </div>
            </HStack>
          </VStack>
        )}
        {currentStep === 2 && (
          <VStack className="justify-start h-full">
            <VStack className="gap-2">
              <div className="p-6 text-lg font-semibold border-b">
                <div className="flex justify-between">Report Filters</div>
                <div className="text-xs font-medium text-gray-400">
                  Apply relevant filters to customise report data for better
                  insights and analysis.
                </div>
              </div>
            </VStack>
            <HStack className="items-center gap-2 px-6 py-4">
              <Label className="text-sm font-normal min-w-[30%]">
                Company Name
              </Label>
              <MultiSelectV2
                _className="min-w-72"
                multiple={true}
                placeholder={"--Select companies--"}
                options={subsidiaryNames}
                handleSelection={(e) => {
                  const subsidiaryIds = subsidiaryList
                    ?.filter((p) => e.includes(p.name))
                    ?.map((p) => p.id);
                  formik.setFieldValue("companyIds", subsidiaryIds);
                }}
                optionValues={
                  subsidiaryList
                    ?.filter((p) => formik.values.companyIds.includes(p.id))
                    ?.map((p) => p.name) || []
                }
              />
            </HStack>
            <HStack className="items-center gap-2 px-6 py-4">
              <Label className="text-sm font-normal min-w-[30%]">
                Department
              </Label>
              <MultiSelectV2
                _className="min-w-72"
                multiple={true}
                placeholder={"--Select departments--"}
                options={departments}
                handleSelection={(e) => {
                  formik.setFieldValue("departments", e);
                }}
                optionValues={formik.values.departments}
              />
            </HStack>
            <HStack className="items-center gap-2 px-6 py-4">
              <Label className="text-sm font-normal min-w-[30%]">
                Shareholder Type
              </Label>
              <MultiSelectV2
                _className="min-w-72"
                multiple={true}
                placeholder={"--Select type--"}
                options={[
                  { value: EmployementType.EMPLOYEE },
                  { value: EmployementType.CONSULTANT },
                ]}
                handleSelection={(e) => {
                  formik.setFieldValue("shareHolderType", e);
                }}
                optionValues={formik.values.shareHolderType}
              />
            </HStack>
            <HStack className="items-center gap-2 px-6 py-4">
              <Label className="text-sm font-normal min-w-[30%]">
                Grant States
              </Label>
              <MultiSelectV2
                _className="min-w-72"
                multiple={true}
                placeholder={"--Select states--"}
                options={grantStates}
                handleSelection={(e) => {
                  formik.setFieldValue("grantStates", e);
                }}
                optionValues={formik.values.grantStates}
              />
            </HStack>
            <HStack className="items-center gap-2 px-6 py-4">
              <Label className="text-sm font-normal min-w-[30%]">
                Plan Names
              </Label>
              <MultiSelectV2
                _className="min-w-72"
                multiple={true}
                placeholder={"--Select plans--"}
                options={planNames}
                handleSelection={(e) => {
                  const filteredIds = plans
                    .filter((p) => e.includes(p.planName))
                    .map((p) => p.esopPlanId);
                  formik.setFieldValue("planIds", filteredIds);
                }}
                optionValues={plans
                  .filter((p) => formik.values.planIds.includes(p.esopPlanId))
                  .map((p) => p.planName)}
              />
            </HStack>
          </VStack>
        )}
        {currentStep === 3 && (
          <>
            <VStack className="justify-start h-full">
              <VStack className="gap-2">
                <div className="p-6 text-lg font-semibold border-b">
                  <div className="flex justify-between">
                    Valuation Selection
                  </div>
                  <div className="text-xs font-medium text-gray-400">
                    Enable the toggle for standard valuations. Upload the Excel
                    for multiple valuations
                  </div>
                </div>
              </VStack>
              <VStack className="px-6 py-4">
                <div className="flex-1">
                  <HStack className="items-center justify-between">
                    <VStack className="">
                      <Label className="pb-0 text-sm font-normal">
                        Use valuations uploaded on the Hissa platform
                      </Label>
                      <span className="font-medium text-gray-400 text-xxs">
                        This will utilize the valuations which has been uploaded
                        previously to the platform
                      </span>
                    </VStack>
                    <SwitchButton
                      onClick={() => {
                        setExistingValuations(!existingValuations);
                      }}
                      className=""
                      value={existingValuations}
                      label=""
                    />
                  </HStack>
                </div>
              </VStack>
              <VStack className="px-6 py-4">
                {existingValuations ? (
                  <VStack className="gap-6">
                    <HStack className="gap-6">
                      <div className="flex-1">
                        <Label className="text-sm font-normal">
                          Valuation Type
                        </Label>
                        <Select
                          options={["FMV", "Intrinsic"]}
                          value={valuationType}
                          onChange={(e) => {
                            setValuationType(e.target.value);
                            if (e.target.value === "Intrinsic") {
                              formik.setFieldValue("attritionValue", 0);
                            }
                          }}
                        ></Select>
                      </div>
                      <div className="flex-1">
                        <Label className="text-sm font-normal">
                          Change Valuation Based On
                        </Label>
                        <Select
                          options={["Grant Date", "Vesting Date"]}
                          value={changeValuationPerVesting}
                          onChange={(e) => {
                            setChangeValuationPerVesting(e.target.value);
                          }}
                        ></Select>
                      </div>
                    </HStack>
                    <VStack>
                      <div className="pt-6 pb-2 text-base font-medium border-b ">
                        <div className="flex justify-between ">
                          Valuations Utilised
                        </div>
                      </div>
                      <Box className="pt-2 ">
                        <table className="w-full">
                          <thead className="text-sm font-medium border-b text-gray-light">
                            <tr className="text-center ">
                              <td className="py-4 align-middle hover:cursor-pointer w-[20%]"></td>
                              <td className="py-4 align-middle hover:cursor-pointer w-[30%]">
                                VALUATION DATE
                              </td>
                              <td className="py-4 align-middle hover:cursor-pointer w-[30%]">
                                VALUATION AMOUNT
                              </td>
                              <td className="py-4 align-middle hover:cursor-pointer w-[20%]"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {allValuations?.map((val, index) => (
                              <tr
                                key={index}
                                className={`${
                                  index !== 0 ? "border-dashed border-y-2" : ""
                                } text-center ${
                                  index % 2 === 0 ? "bg-gray-50" : ""
                                }`}
                              >
                                <td className="justify-center py-4 w-[20%]"></td>
                                <td className="justify-center py-4 w-[30%]">
                                  <Box className="">
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {val.valuationDate}
                                    </p>
                                  </Box>
                                </td>
                                <td className="justify-center py-4 w-[30%]">
                                  <Box className="">
                                    <p
                                      className={`text-sm font-medium text-gray-dark`}
                                    >
                                      {val.valuationAmount}
                                    </p>
                                  </Box>
                                </td>
                                <td className="justify-center py-4 w-[20%]"></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    </VStack>
                  </VStack>
                ) : (
                  <VStack className="">
                    <FileDropzone />
                    <div className="pt-8 pb-2 text-base font-medium ">
                      <div className="flex justify-start pl-6">
                        Download the excel template
                      </div>
                    </div>
                    <HStack
                      className="items-center justify-between gap-2 p-4 px-8 text-center border-2 rounded-lg cursor-pointer"
                      onClick={() => {
                        downloadTemplate();
                      }}
                    >
                      <HStack className="items-center gap-2 text-center ">
                        <Icon icon="vscode-icons:file-type-excel" height={30} />
                        <p className="text-base font-medium ">
                          Multiple_valuations_ESOP_accounting_template.xlsx
                        </p>
                      </HStack>
                      <HStack className="items-center ">
                        <Icon
                          className=""
                          icon="material-symbols:download-sharp"
                          height={24}
                        />
                      </HStack>
                    </HStack>
                  </VStack>
                )}
              </VStack>
            </VStack>
          </>
        )}
        <VStack className="justify-end">
          <HStack className="justify-between px-4 py-6 mt-7">
            <ButtonSecondary
              onClick={() => handleBackClick()}
              className="text-gray-400 bg-slate-50"
            >
              {currentStep !== 1 ? "Back" : "Cancel"}
            </ButtonSecondary>
            <ButtonPrimary
              onClick={() => handleNextClick()}
              disabled={disableDownload()}
            >
              {currentStep === 3 ? "Generate Report" : "Next"}
            </ButtonPrimary>
          </HStack>
        </VStack>
      </VStack>
    </HStack>
  );
};
export default EsopAccountingDialog;
