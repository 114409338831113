export interface Result {
  resultCode: string;
  reason: string;
}
export type Role =
  | "ROLE_ADMIN"
  | "ROLE_OPTIONS_CREATOR"
  | "ROLE_OPTIONS_APPROVER"
  | "ADMIN_VIEWER"
  | "ROLE_ESOP_ADMIN"
  | "ROLE_ESOP_VIEWER";
export type GrantStates =
  | "CREATED"
  | "FOR_APPROVAL"
  | "APPROVED"
  | "OFFERED"
  | "GRANTED"
  | "WITHDRAW"
  | "NOT_APPROVED";

export enum GrantState {
  CREATED = "CREATED",
  FOR_APPROVAL = "FOR_APPROVAL",
  APPROVED = "APPROVED",
  OFFERED = "OFFERED",
  GRANTED = "GRANTED",
  WITHDRAW = "WITHDRAW",
  NOT_APPROVED = "NOT_APPROVED",
}
