import { Icon } from "@iconify/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import _ from "lodash";
import FileInput from "../../components/shared/FileInput";
import { Input, Label } from "../../components/shared/InputField";
import LazyPdfDocument from "../../components/shared/LazyPdfDocument";
import { Select } from "../../components/shared/Select";
import Tooltip from "../../components/shared/Tooltip";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../../components/utils";
import {
  useAddValuation,
  useEditValuation,
  useValuationDetails,
} from "../../queries/Valuation";
import { Valuation, ValuationSource } from "../../types/ValuationType";
import convertToBase64 from "../../utils/convertToBase64";
import { getCurrencySymbol } from "../../utils/currencyFormatter";
import { formatDate } from "../../utils/date";
import { limitString } from "../../utils/string";
import { useEsopPlans } from "../../queries/esopPlan";

type AddOrEditValuationProps = {
  mode: "Edit" | "Add";
  data?: Valuation;
  onClose: () => void;
};

function AddOrEditValuation(props: AddOrEditValuationProps) {
  const currencySymbol = getCurrencySymbol();
  const { data, mode, onClose } = props;
  const { data: _plans } = useEsopPlans();
  const allPlans = _plans || [];
  function getUnitType() {
    const unitTypes = ["All"];
    const planUnitTypes = _.uniq(allPlans.map((p) => p.unitType.toUpperCase()));
    return planUnitTypes.length === 1
      ? unitTypes
      : [...unitTypes, ...planUnitTypes];
  }
  const baseValues: Valuation = {
    name: "",
    dateOfValuation: "",
    reportDate: "",
    unitType: "All",
    valuationSource: ValuationSource.COMPANY,
    pps: 0,
    valuationMethodology: "",
    valuer: "",
    valuerRgNo: "",
    valuationReport: "",
    valuationReportName: "",
    valuationReportType: "",
  };

  function getInitialValuesEditing(data: Valuation) {
    return {
      id: data.id,
      name: data.name,
      dateOfValuation: formatDate(data.dateOfValuation),
      reportDate: formatDate(data.reportDate),
      unitType: data.unitType,
      valuationSource: data.valuationSource,
      pps: data.pps,
      valuationMethodology: data.valuationMethodology,
      valuer: data.valuer,
      valuerRgNo: data.valuerRgNo,
      valuationReport: data.valuationReport,
      valuationReportName: data.valuationReportName,
      valuationReportType: data.valuationReportType,
      isReportOverrided: false,
      strikePrice: data.strikePrice,
    };
  }

  function getInitialValues() {
    if (mode === "Edit" && data) {
      return getInitialValuesEditing(data);
    } else {
      return baseValues;
    }
  }

  const [documentDialog, setDocumentDialog] = useState<{
    open: boolean;
    valuation?: Valuation;
  }>({ open: false, valuation: undefined });

  function handleDelete() {
    formik.setFieldValue("isReportOverrided", true);
    formik.setFieldValue("valuationReport", "");
    formik.setFieldValue("valuationReportName", "");
    formik.setFieldValue("valuationReportType", "");
  }

  const { mutate: addValuation, isLoading: isAddLoading } = useAddValuation();
  const { mutate: editValuation, isLoading: isEditLoading } =
    useEditValuation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Valuation name is required"),
    dateOfValuation: Yup.date().required("Date of valuation is required"),
    reportDate: Yup.date()
      .required("Report date is required")
      .max(new Date(), "Valid from date cannot be in future"),
    pps: Yup.number()
      .required("Value is required")
      .moreThan(0, "Value should be more than zero"),
    valuationMethodology: Yup.string(),
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: (values) => {
      if (mode === "Add") {
        addValuation(values, {
          onSuccess: () => {
            toast("Valuation Added Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        editValuation(values, {
          onSuccess: () => {
            toast("Valuation Edited Successfully!", { type: "success" });
            onClose();
          },
          onError: (err: any) => {
            toast(err.response.data.reason, { type: "error", autoClose: 2000 });
          },
        });
      }
    },
  });
  async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];

    const acceptedFiles = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!acceptedFiles.includes(file.type)) {
      toast.error("Please upload pdf or docx file");
      return;
    }
    const acceptedExtensions = [".pdf", ".docx"];
    const accept = acceptedExtensions.some((e: string) =>
      file.name.endsWith(e)
    );
    if (!accept) {
      toast.error("Please upload pdf or docx file");
      return;
    }

    formik.setFieldValue("valuationReportName", file?.name);
    const base64 = await convertToBase64(file);
    formik.setFieldValue("valuationReport", base64);
    formik.setFieldValue("isReportOverrided", true);
    formik.setFieldValue("valuationReportType", file.type);
  }
  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit" ? "Edit Valuation" : "Add Valuation"}{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            <Icon icon="material-symbols:close-rounded" />
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 gap-9 py-7">
        <HStack className="items-center gap-8">
          <div className="flex-1">
            <Label>Name</Label>
            <Input type="text" {...formik.getFieldProps("name")} />
            {formik.touched.name && formik.errors.name && (
              <Error text={formik.errors.name} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Report Date</Label>
            <Input
              type="date"
              disabled={mode === "Edit"}
              {...formik.getFieldProps("reportDate")}
            />
            {formik.touched.reportDate && formik.errors.reportDate && (
              <Error text={formik.errors.reportDate} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Date</Label>
            <Input
              type="date"
              disabled={mode === "Edit"}
              {...formik.getFieldProps("dateOfValuation")}
            />
            {formik.touched.dateOfValuation &&
              formik.errors.dateOfValuation && (
                <Error text={formik.errors.dateOfValuation} />
              )}
          </div>
        </HStack>
        <HStack className="items-center gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Unit type</Label>
            <Select
              options={getUnitType()}
              isPlaceHolderDisabled={true}
              {...formik.getFieldProps("unitType")}
            />
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">
              Value ({currencySymbol})
            </Label>
            <Input type="number" {...formik.getFieldProps("pps")} />
            {formik.touched.pps && formik.errors.pps && (
              <Error text={formik.errors.pps} />
            )}
          </div>
          <div className="flex-1">
            <Label className="text-sm font-normal">Method</Label>
            <Input
              type="text"
              {...formik.getFieldProps("valuationMethodology")}
            />
            {formik.touched.valuationMethodology &&
              formik.errors.valuationMethodology && (
                <Error text={formik.errors.valuationMethodology} />
              )}
          </div>
        </HStack>
        <HStack className="items-center gap-8">
          <div className="flex-1">
            <Label className="text-sm font-normal">Valuation Source</Label>
            <Select
              options={["COMPANY", "EXTERNAL"]}
              isPlaceHolderDisabled={true}
              {...formik.getFieldProps("valuationSource")}
            />
          </div>
          {formik.values.valuationSource === ValuationSource.EXTERNAL ? (
            <div className="flex-1">
              <Label className="text-sm font-normal">Strike Price</Label>
              <Input type="number" {...formik.getFieldProps("strikePrice")} />
            </div>
          ) : (
            <div className="flex-1"></div>
          )}
          <div className="flex-1"></div>
        </HStack>
        {formik.values.valuationSource === ValuationSource.EXTERNAL ? (
          <HStack className="items-center gap-8">
            <div className="flex-1">
              <Label className="text-sm font-normal">Valuer</Label>
              <Input type="text" {...formik.getFieldProps("valuer")} />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Valuer Reg No</Label>
              <Input type="string" {...formik.getFieldProps("valuerRgNo")} />
            </div>
            <div className="flex-1">
              <Label className="text-sm font-normal">Valuation Report</Label>
              <div className="py-1.5">
                {mode === "Edit" && formik.values.valuationReport ? (
                  <div className="flex flex-row items-center justify-between cursor-pointer align-center">
                    <button
                      className="text-red-500 underline"
                      onClick={() =>
                        setDocumentDialog({ open: true, valuation: data })
                      }
                    >
                      {formik.values.valuationReportName
                        ? formik.values.valuationReportName.length > 20
                          ? limitString(formik.values.valuationReportName, 20)
                          : formik.values.valuationReportName
                        : "Valuation Report.docx"}
                    </button>
                    <button
                      onClick={() => handleDelete()}
                      className="ml-6 text-zinc-300 hover:scale-105"
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                ) : (
                  <Tooltip text={formik.values.valuationReportName || ""}>
                    <HStack>
                      <FileInput
                        accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        file={
                          formik.values.valuationReportName
                            ? formik.values.valuationReportName.length > 20
                              ? limitString(
                                  formik.values.valuationReportName,
                                  20
                                )
                              : formik.values.valuationReportName
                            : "Attach File"
                        }
                        onChange={(e) => {
                          handleFileUpload(e);
                        }}
                      />
                      <button
                        onClick={() => handleDelete()}
                        className="ml-6 text-zinc-300 hover:scale-105"
                      >
                        <DeleteIcon />
                      </button>
                    </HStack>
                  </Tooltip>
                )}
              </div>
            </div>
          </HStack>
        ) : (
          <div className="h-[76px]"></div>
        )}
        <Dialog
          open={documentDialog.open}
          onClose={() => setDocumentDialog({ open: false })}
          maxWidth="lg"
        >
          <LazyPdfDocument
            url={documentDialog?.valuation?.valuationReport ?? ""}
          />
        </Dialog>
        <HStack className="justify-between">
          <ButtonPrimary1 onClick={() => onClose()}>Cancel</ButtonPrimary1>
          <ButtonPrimary
            type="submit"
            onClick={(e) => {
              formik.handleSubmit();
            }}
            loading={isAddLoading || isEditLoading}
          >
            Save
          </ButtonPrimary>
        </HStack>
      </VStack>
    </>
  );
}

export default AddOrEditValuation;
