import { Icon } from "@iconify/react";
import { Dialog, DialogProps } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useGenerateEmployeeCredentials } from "../../queries/employees";
import { Employee, EmployeeCredentialGeneration } from "../../types/Employee";
import { ButtonPrimary, ButtonSecondary, HStack, VStack } from "../utils";
import { CheckBoxWithLabel } from "./InputField";

export type GenerateCredentialsTypes = {
  error?: boolean;
  message?: string;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  employeeList: Employee[];
  empWithCred: Employee[];
  empWithoutCred: Employee[];
} & DialogProps;

function GenerateCredentials({
  employeeList,
  empWithCred,
  empWithoutCred,
  error = false,
  message = "Are you sure you want to generate credentials?",
  primaryActionText = "Generate",
  secondaryActionText = "Cancel",
  onPrimaryAction = () => {},
  onSecondaryAction = () => {},
  ...dialogProps
}: GenerateCredentialsTypes) {
  const [existing, setExisting] = useState(true);
  const [sendEmails, setSendEmails] = useState(true);
  const { mutate: generateEmployeeCredentials, isLoading } =
    useGenerateEmployeeCredentials();
  let ids: string[] = [];
  function genCred() {
    if (existing) {
      ids = employeeList.map((employee) => employee.id);
    } else if (!existing) {
      ids = employeeList.map((employee) =>
        employee.isLoginProvided !== true ? employee.id : ""
      );
    }
    const generateObj: EmployeeCredentialGeneration = {
      employeeIds: employeeList.map((emp) => emp.id),
      regenerateExisting: existing,
      sendCredentialsGenerationEmail: sendEmails,
    };
    generateEmployeeCredentials(generateObj, {
      onSuccess: () => {
        toast("Credentials Generated successfully!", {
          type: "success",
          autoClose: 2000,
        });
        onSecondaryAction();
      },
      onError: () => {
        toast("Something went wrong", { type: "error", autoClose: 2000 });
      },
    });
  }
  return (
    <Dialog {...dialogProps} maxWidth="lg">
      <VStack className="justify-between p-9 bg-white rounded h-[321px]">
        <VStack className="items-center justify-center gap-6 grow">
          {!error ? (
            <Icon icon="ep:warning" color="#FFE99B" height={72} />
          ) : (
            <Icon icon="codicon:error" color="#ff0000" height={72} />
          )}
        </VStack>
        <p className="items-center justify-center gap-4 font-medium text-dark grow text-xs3 text-center">
          {message}
        </p>
        <HStack className="p-2 text-blue-800 bg-blue-100 rounded gap-4">
          <VStack>
            <div className="pt-0">
              <Icon
                icon="material-symbols:info-outline-rounded"
                height={24}
                width={24}
                className="w-full text-xs font-medium text-blue-800 rounded cursor-pointer"
              />
            </div>
          </VStack>
          <VStack>
            <p className="">{`${
              employeeList.length
            } credentials will be generated${
              empWithCred.length > 0
                ? `, including ${empWithCred.length} regenerations.`
                : ""
            }`}</p>{" "}
          </VStack>
        </HStack>
        <HStack className="justify-between gap-9 pt-4">
          <ButtonSecondary
            onClick={() => onSecondaryAction()}
            className="text-gray-400 bg-slate-50"
          >
            {secondaryActionText}
          </ButtonSecondary>
          <ButtonPrimary onClick={() => genCred()} loading={isLoading}>
            {primaryActionText}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Dialog>
  );
}

export default GenerateCredentials;
