export interface Valuation {
  name: string;
  id?: string;
  dateOfValuation: string;
  reportDate: string;
  unitType: string;
  pps: number;
  valuationSource: ValuationSource;
  valuationMethodology: string;
  valuer: string;
  valuerRgNo: string;
  valuationReport: string;
  valuationReportName?: string;
  valuationReportType?: string;
  isReportOverrided?: boolean;
  strikePrice?: number;
}

export enum ValuationSource {
  EXTERNAL = "EXTERNAL",
  COMPANY = "COMPANY",
}

export const ValuationSourceType = [
  { text: "Company", value: ValuationSource.COMPANY },
  { text: "External", value: ValuationSource.EXTERNAL },
];
