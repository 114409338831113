import { createContext, useState } from "react";
import { Box, HStack, VStack } from "../../components/utils";
import BCHeader from "../../shared/BCHeader";
import ESignSettings from "./ESignSettings";
import EmailSettings from "./EmailSettings";
import EmailTemplateSettings from "./EmailTemplateSettings";
import EmployeePortalSettings from "./EmployeePortalSettings";
import SecuritySettings from "./SecuritySettings";
import CCEmailSettings from "./CCEmailSettings";
import GrantLetterSettings from "./GrantLetterSettings";
import EmployeeDatasourceSettings from "./EmployeeDatasourceSettings";
import { useGetAllCompanyConfigs } from "../../queries";

export const SiteSettingsContext = createContext(false);
function SiteSettings() {
  const [activeSetting, setActiveSetting] = useState<string>("Email Settings");
  const { data: configs } = useGetAllCompanyConfigs();
  return (
    <VStack className="gap-4">
      <div className="flex items-baseline justify-between">
        <BCHeader
          className="items-baseline "
          bcTitle="Settings"
          bcSubTitle="Site Settings"
        />
      </div>
      <Box className="my-auto rounded-lg drop-shadow-md">
        <HStack className="w-full gap-4">
          <Box className="h-full min-h-full bg-white rounded-lg w-21 min-w-min">
            <Box className="items-center p-4 mt-10 text-lg font-medium text-gray-dark whitespace-nowrap">
              Site Settings Menu
            </Box>
            <VStack className="justify-between h-[500px]">
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeSetting === "Email Settings"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveSetting("Email Settings")}
              >
                Email Settings
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeSetting === "Email CC Settings"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveSetting("Email CC Settings")}
              >
                Email CC Settings
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeSetting === "Employee Portal Settings"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveSetting("Employee Portal Settings")}
              >
                Employee Portal Settings
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeSetting === "Grant Settings"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveSetting("Grant Settings")}
              >
                Grant Settings
              </Box>
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeSetting === "ESign Settings"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveSetting("ESign Settings")}
              >
                E-Sign Settings
              </Box>
              {(configs || []).length > 0 && (
                <Box
                  className={`cursor-pointer  form-label text-sm font-medium  p-4  whitespace-nowrap ${
                    activeSetting === "Employee Datasource Settings"
                      ? "rounded text-orange-501"
                      : ""
                  }`}
                  onClick={() =>
                    setActiveSetting("Employee Datasource Settings")
                  }
                >
                  Employee Datasource Settings
                </Box>
              )}
              <Box
                className={`cursor-pointer  form-label text-sm font-medium   p-4  whitespace-nowrap ${
                  activeSetting === "Email Template Settings"
                    ? "rounded text-orange-501"
                    : ""
                }`}
                onClick={() => setActiveSetting("Email Template Settings")}
              >
                Email Template Settings
              </Box>
            </VStack>
          </Box>
          <Box className="flex overflow-y-auto bg-white grow">
            {activeSetting === "Email Settings" && <EmailSettings />}
            {activeSetting === "Email CC Settings" && <CCEmailSettings />}
            {activeSetting === "Employee Portal Settings" && (
              <EmployeePortalSettings />
            )}
            {activeSetting === "Employee Datasource Settings" && (
              <EmployeeDatasourceSettings />
            )}
            {activeSetting === "Grant Settings" && <GrantLetterSettings />}
            {activeSetting === "ESign Settings" && <ESignSettings />}
            {activeSetting === "Security Settings" && <SecuritySettings />}
            {activeSetting === "Email Template Settings" && (
              <EmailTemplateSettings />
            )}
          </Box>
        </HStack>
      </Box>
    </VStack>
    //     </Form>
    //   )}
    // </Formik>
  );
}

export default SiteSettings;
