import Dialog from "@mui/material/Dialog";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { downloadPoolStatement } from "../../api/Esop";
import { Action } from "../../components/shared/Dropdown";
import DropdownButton from "../../components/shared/DropdownButton";
import { Box, VStack, getCompanyName } from "../../components/utils";
import AddEmployee from "../../modals/AddEmployee";
import { useEsopPlans, usePoolDetails } from "../../queries";
import BCHeader from "../../shared/BCHeader";
import PendingRequests from "../../shared/PendingRequests";
import CardDraft from "../../shared/card-draft";
import CardPend from "../../shared/card-pend";
import CardPendingApproval from "../../shared/card-pending-approval";
import CardPlan from "../../shared/card-plan";
import CardPool from "../../shared/card-pool";
import { usePermissionStore } from "../../store/permissionStore";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";
import { determineUserAccessToResource } from "../../utils/auth";
import useIsMobile from "../../utils/detectDevice";
import {
  Action as DefaultAction,
  Resource,
} from "../../utils/interfaces/Companies";
import PlansTable from "../allPlans/PlansTable";
import { AddOrEditGrant } from "./AddOrEditGrant";
import VestingDetails from "./vestingDetails";

function EsopOverview(): React.ReactElement {
  const navigate = useNavigate();
  const [grantDialogOpen, setGrantDialogOpen] = useState(false);
  const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);
  const { data, isPlaceholderData } = useEsopPlans();
  const { data: poolDetails } = usePoolDetails();
  const isPoolExists = !!poolDetails?.isPoolExists;
  const companyName = getCompanyName();

  const handleAction = (action: Action) => {
    if (action.disabled) {
      return;
    }
    if (action.name === "Create a New grant") {
      navigate("/options/grant/add");
    }
    if (action.name === "Create a New Plan") {
      navigate("/options/plan/add");
    }
    if (action.name === "Add Option Holder") {
      setEmployeeDialogOpen(!employeeDialogOpen);
    }
    if (action.name === "Edit Pool" || action.name === "Add Pool") {
      navigate("/options/add-edit-pool");
    }
    if (action.name === "Download Pool Statement") {
      downloadPoolStatement().then((data) => {
        const reportDto = data.data;
        convertBase64ToBlob(reportDto.base64File, reportDto.fileType).then(
          (blobObject) => {
            downloadBlobObject(
              blobObject,
              `Pool Statement_${companyName}.xlsx`
            );
          }
        );
      });
    }
  };
  const { isMobile } = useIsMobile();
  const { permission } = usePermissionStore();

  return (
    <VStack className={`gap-4 ${isPlaceholderData ? "loading" : ""} `}>
      <div className={`flex items-baseline justify-between`}>
        <BCHeader
          className="items-baseline"
          bcTitle="Overview and Insights"
          bcDescription="Access a consolidated view of key metrics and updates on your equity plans, participant engagement, and upcoming vesting events."
        />
        <DropdownButton
          actions={[
            {
              name: "Create a New Plan",
              disabled: !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.OptionsPlan,
                DefaultAction.Create
              ),
              tooltipText: "No Permission",
              type: "Permission",
            },
            {
              name: "Create a New grant",
              disabled: !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.Grant,
                DefaultAction.Create
              ),
              tooltipText: "No Permission",
              type: "Permission",
            },
            {
              name: "Add Option Holder",
              disabled: !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.Employee,
                DefaultAction.Create
              ),
              tooltipText: "No Permission",
              type: "Permission",
            },
            {
              name: `${isPoolExists ? "Edit Pool" : "Add Pool"}`,
              disabled: !determineUserAccessToResource(
                permission?.aclList || [],
                Resource.OptionsPool,
                DefaultAction.Create
              ),
              tooltipText: "No Permission",
              type: "Permission",
            },
          ]}
          onAction={(action) => handleAction(action)}
        />
      </div>
      <div className="grid gap-6 overflow-auto rounded-lg lg:grid-cols-3 md:grid-cols-2">
        <CardPool />
        <CardPlan />
        <CardDraft />
        <CardPend />
        <PendingRequests />
        <CardPendingApproval />
      </div>
      <VestingDetails />
      <Box className={`w-full ${isMobile ? "overflow-x-auto" : "grow"} `}>
        {data && (
          <PlansTable data={data} isPlaceholderData={isPlaceholderData} />
        )}
      </Box>
      <Dialog open={employeeDialogOpen} maxWidth="md">
        <AddEmployee
          onClose={() => setEmployeeDialogOpen(!employeeDialogOpen)}
          data={[]}
        />
      </Dialog>
      <Dialog open={grantDialogOpen} maxWidth="md">
        <AddOrEditGrant onClose={() => setGrantDialogOpen(!grantDialogOpen)} />
      </Dialog>
    </VStack>
  );
}

export default EsopOverview;
