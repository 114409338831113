import { useFormikContext } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Dialog } from "@mui/material";
import {
  Box,
  HStack,
  VStack,
  ButtonPrimary,
  ButtonPrimary1,
  isEsopViewer,
  ButtonSecondary,
} from "../../components/utils";
import { usePermissionStore } from "../../store";
import {
  useEmployees,
  useEsopPlans,
  useGetAllCompanyConfigs,
  useGetConfigDetails,
  useGrants,
  useSaveNewExportConfig,
  useUpdateExportSettings,
  useUpdateSyncSettings,
} from "../../queries";
import { formatDisplayDate } from "../../utils/date";
import {
  MasterApiData,
  MasterSettings,
  NewConfigSettings,
  Settings,
} from "../../types/ExportExcelEmployee";
import { MultiSelect } from "../../components/shared/MultiSelect";
import { SwitchButton } from "../../components/shared/SwitchButton";
import { Input } from "../../components/shared/InputField";
import { Select } from "../../components/shared/Select";
import { useValuationDetails } from "../../queries/Valuation";
import BCHeader from "../../shared/BCHeader";
import { MultiSelectV2 } from "../../components/shared/MultiSelectV2";
import ConfigForm from "./ConfigForm";

function EmployeeDatasourceSettings() {
  const { permission } = usePermissionStore();
  const { data: planData, isFetched: planDataFetched } = useEsopPlans();
  const { data: grantsData, isFetched: grantsDataFetched } = useGrants();
  const { data: configs } = useGetAllCompanyConfigs();
  const [configId, setConfigId] = useState<string>("");
  const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
  const { data: configDetails, refetch: refetchDetails } =
    useGetConfigDetails(configId);
  const { data: _valuations } = useValuationDetails();
  const { mutate: updateSettings } = useUpdateSyncSettings();
  const { mutate: editMasterConfig } = useUpdateExportSettings();
  const [activeTab, setActiveTab] = useState<string>("Settings");
  const [configSettings, setConfigSettings] = useState<Settings>();
  const [isSyncEnabled, setIsSyncEnabled] = useState<boolean>(false);
  const valuations = useMemo(() => _valuations || [], [_valuations]);
  const dataSets = useMemo(() => {
    const dataSources: any[] = [];
    if (configs) {
      configs.forEach((config) => {
        const lastSync = config?.employeeDataSyncHistories[0];
        const dataSource = {
          ...config,
          lastSyncedOn: lastSync?.createdAt
            ? formatDisplayDate(new Date(lastSync?.createdAt))
            : "Never",
          lastSyncStatus: lastSync?.syncStatus || "Not Synced",
          lastSyncNote: lastSync?.note || "",
        };
        dataSources.push(dataSource);
      });
    }
    return dataSources;
  }, [configs]);
  const saveSettings = () => {
    setIsDataSaving(true);
    if (configDetails?.masterDataType === "GRANT") {
      const updateBody: NewConfigSettings = {
        planFilter: {
          planId: {
            include: [...planFilter],
            exclude: [],
          },
        },
        grantFilter: {
          grantType: {
            include: [grantTypeFilter],
            exclude: [],
          },
        },
        employeeFilter: {
          employMentType: {
            include: [employeeTypeFilter],
            exclude: [],
          },
        },
        enabled: isSyncEnabled,
        name: configName,
        configId,
      };
      editMasterConfig(updateBody, {
        onSuccess: () => {
          toast("Settings updated successfully", {
            type: "success",
            autoClose: 3000,
          });
          refetchDetails();
          setIsDataSaving(false);
        },
        onError: () => {
          toast("Error updating settings", {
            type: "error",
            autoClose: 3000,
          });
          setIsDataSaving(false);
        },
      });
    } else {
      const updateBody: Settings & {
        isSyncEnabled: boolean;
        configId: string;
      } = {
        syncFrequency: Number(configSettings?.syncFrequency) || 0,
        additionalSettings: configSettings?.additionalSettings || {},
        dataToSync: configSettings?.dataToSync || [],
        considerResignationDateForLapsing:
          configSettings?.considerResignationDateForLapsing || false,
        autoTransferToPersonalEmail:
          configSettings?.autoTransferToPersonalEmail || false,
        valuationForGrant: configSettings?.valuationForGrant || "",
        isSyncEnabled,
        configId,
        syncMode: configSettings?.syncMode || "",
      };
      updateSettings(updateBody, {
        onSuccess: () => {
          toast("Settings updated successfully", {
            type: "success",
            autoClose: 3000,
          });
          refetchDetails();
          setIsDataSaving(false);
        },
        onError: () => {
          toast("Error updating settings", {
            type: "error",
            autoClose: 3000,
          });
          setIsDataSaving(false);
        },
      });
    }
  };
  const { data: employee } = useEmployees();
  const [configName, setConfigName] = useState<string>("");
  const [exportType, setExportType] = useState<MasterApiData>(
    MasterApiData.GRANT
  );
  const [planFilter, setPlanFilter] = useState<string[]>([]);
  const [grantTypeFilter, setGrantTypeFilter] = useState<string>("");
  const [employeeTypeFilter, setEmployeeTypeFilter] = useState<string>("");

  const newConfigSettings = useMemo(() => {
    const configSettings = configDetails?.settings as MasterSettings;
    if (!configSettings) return {};
    const settings: NewConfigSettings = {
      addingNewConfig: false,
      type: exportType,
      name: configDetails?.name || "",
      enabled: isSyncEnabled,
      grantFilter: configSettings.grantFilter,
      planFilter: configSettings.planFilter,
      employeeFilter: configSettings.employeeFilter,
      credentialData: configDetails?.credentialData,
      configId: configDetails?.id,
    };
    return settings;
  }, [configDetails]);

  const [newConfig, setNewConfig] = useState<NewConfigSettings>({
    addingNewConfig: false,
  });
  useEffect(() => {
    if (configId) {
      refetchDetails();
    }
  }, [configId]);
  const handleAddNewConfig = () => {
    setNewConfig({ ...newConfig, addingNewConfig: true });
  };
  useEffect(() => {
    if (configDetails) {
      configDetails.settings.syncMode = configDetails.syncMode || "AUTOMATIC";
      setConfigSettings(configDetails.settings);
      setIsSyncEnabled(configDetails.isEnabled);
    }
  }, [configDetails]);
  const options = [
    { value: "ALL" },
    { value: "New Employees" },
    { value: "Changes" },
    { value: "Status Changes" },
    { value: "Resignations" },
  ];
  const imageMaps = {
    greyTip: "/greytip.png",
    darwinBox:
      "https://equion-dev.s3.us-west-2.amazonaws.com/assets/dboxminilogo.png",
    compup: "/earth.png",
  };
  const tabs = ["History", "Settings"];
  const fetchSourceImage = (masterDataType: string) => {
    if (masterDataType === "DarwinboxDataset") {
      return imageMaps.darwinBox;
    }
    if (masterDataType === "GRANT") {
      return "https://rulezerowebsite.s3.amazonaws.com/hissa_site_logo_new.png";
    }
    return imageMaps.greyTip;
  };
  const fetchDestinationImage = (masterDataType: string) => {
    if (masterDataType === "DarwinboxDataset") {
      return "https://rulezerowebsite.s3.amazonaws.com/hissa_site_logo_new.png";
    }
    if (masterDataType === "GRANT") {
      return imageMaps.compup;
    }
    return "";
  };

  return (
    <Box className="lg:w-[850px] md:w-[425px] p-4 bg-white rounded-lg ">
      <VStack className="justify-between p-4 gap-9">
        <Box className="items-center mt-6 text-lg font-medium text-gray-dark">
          Employee Datasource Settings
          <p className="text-gray-400 text-xxs">
            View and configure your Employee Datasource Settings here.
          </p>
        </Box>
        <VStack className="gap-8 max-h-[600px] overflow-y-auto">
          {!configId && (
            <>
              <HStack className="justify-end">
                <ButtonPrimary
                  onClick={() => {
                    handleAddNewConfig();
                  }}
                >
                  Add Export Config
                </ButtonPrimary>
              </HStack>
              <HStack className="justify-between w-full">
                <table className="w-full space-x-2 table-space">
                  <thead className="text-xs font-medium text-[#B5B5C3] ">
                    <tr className="border-b border-[#E4E6EF] border-dashed items-center text-center">
                      <td className="w-1/4">Name & Type</td>
                      <td>Last Synced On</td>
                      <td>Mode</td>
                      <td>Last Sync Status</td>
                      <td>Enabled</td>
                      <td className="w-1/4">Description</td>
                    </tr>
                  </thead>
                  <tbody className="text-xs font-medium text-dark">
                    {dataSets.map((dataSet) => (
                      <tr
                        key={dataSet.id}
                        className="border-b border-[#E4E6EF] border-dashed cursor-pointer hover:bg-slate-50 h-16"
                        onClick={() => setConfigId(dataSet.id)}
                      >
                        <td className="h-16 p-4 w-96">
                          <VStack className="items-start text-center">
                            <HStack className="items-center w-full gap-3">
                              <img
                                src={fetchSourceImage(dataSet.masterDataType)}
                                alt="logo"
                                className="w-8 h-8"
                              />
                              <Icon icon="line-md:arrow-right" width={20} />
                              <img
                                src={fetchDestinationImage(
                                  dataSet.masterDataType
                                )}
                                alt="logo"
                                className="max-w-8 max-h-8"
                              />
                            </HStack>
                          </VStack>
                        </td>
                        <td className="p-4 text-center">
                          {dataSet.lastSyncedOn}
                        </td>
                        <td className="p-4 text-center">{dataSet.syncMode}</td>
                        <td className="p-4 text-center">
                          {dataSet.lastSyncStatus}
                        </td>
                        <td className="p-4 text-center">
                          <input
                            type="checkbox"
                            className="text-white cursor-not-allowed accent-orange-501"
                            checked={dataSet.isEnabled}
                            value={dataSet.isEnabled}
                          />
                        </td>
                        <td className="p-4 text-center">
                          {dataSet.lastSyncNote}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </HStack>
            </>
          )}
          {configId && configDetails && (
            <>
              <HStack className="justify-between w-full">
                <BCHeader
                  onClick={() => {
                    setConfigId("");
                    setConfigSettings(undefined);
                  }}
                  bcTitle="All Configs"
                  bcSubTitle={configDetails.masterDataType}
                />
                <Box className="inline-flex justify-center gap-4 text-lg font-medium">
                  <Box
                    className={`bottom-5 flex items-center justify-center w-auto px-2 h-6 rounded-full ${
                      isSyncEnabled ? "bg-green-300" : "bg-red-300"
                    }`}
                  >
                    <Icon
                      icon={isSyncEnabled ? "mdi:check" : "mdi:close"}
                      className="mr-1 text-white"
                    />
                    <span className="text-xs text-white">
                      {isSyncEnabled ? "Enabled" : "Disabled"}
                    </span>
                  </Box>
                  <SwitchButton
                    className={`inline-flex ${
                      isSyncEnabled ? "cursor-default " : ""
                    } items-center`}
                    value={isSyncEnabled}
                    label={""}
                    onClick={() => {
                      setIsSyncEnabled(!isSyncEnabled);
                    }}
                  />
                </Box>
              </HStack>
              {/* <HStack className="gap-8 mb-0 border-t-2 border-dotted flex-center">
                {tabs.map((item, i) => (
                  <Box
                    onClick={() => setActiveTab(item)}
                    key={i}
                    className={`text-xs3 font-semibold cursor-pointer py-4 ${item === activeTab
                        ? "border-b-2 border-solid border-red-500 text-red-500"
                        : "text-gray-201 "
                      }`}
                  >
                    {item}
                  </Box>
                ))}
              </HStack> */}
              {activeTab === "History" && (
                <VStack className="gap-4 mt-4 max-h-[500px] overflow-y-auto">
                  {configs
                    ?.find((config) => config.id === configId)
                    ?.employeeDataSyncHistories?.map((history) => (
                      <Box
                        key={history.id}
                        className="p-4 bg-gray-100 rounded-lg shadow-md"
                      >
                        <HStack className="justify-between">
                          <Box>
                            <p className="font-medium">Sync Status:</p>
                            <p>{history.syncStatus}</p>
                          </Box>
                          <Box>
                            <p className="font-medium">Note:</p>
                            <p>{history.note}</p>
                          </Box>
                          <Box>
                            <p className="font-medium">Created At:</p>
                            <p>
                              {formatDisplayDate(new Date(history.createdAt))}
                            </p>
                          </Box>
                        </HStack>
                      </Box>
                    ))}
                </VStack>
              )}
              {activeTab === "Settings" && (
                <VStack className="gap-4 mt-4 max-h-[500px] overflow-y-auto">
                  {(configDetails.masterDataType === "DarwinboxDataset" ||
                    configDetails.masterDataType === "greytip") && (
                    <Box className="rounded-lg ">
                      <HStack className="justify-between gap-4 p-2">
                        <Box className="flex items-center justify-between w-full">
                          <p className="mt-4 mr-2 text-sm font-medium form-label">
                            Sync Frequency (in Days):
                          </p>
                          <Input
                            type="number"
                            value={configSettings?.syncFrequency}
                            onChange={(e) => {
                              const value = Number(e.target.value);
                              if (value < 1) {
                                toast(
                                  "Sync Frequency should be greater than 0",
                                  {
                                    type: "error",
                                    autoClose: 3000,
                                  }
                                );
                                return;
                              }
                              setConfigSettings((prevSettings) => {
                                if (!prevSettings) return prevSettings;
                                return {
                                  ...prevSettings,
                                  syncFrequency: Number(e.target.value),
                                };
                              });
                            }}
                            className="p-2 border rounded lg:w-48 md:w-32"
                          />
                        </Box>
                      </HStack>
                      <HStack className="gap-4 p-2">
                        <Box className="flex items-center justify-between w-full gap-2">
                          <p className="mt-4 mr-2 text-sm font-medium form-label">
                            Sync Mode:
                          </p>
                          <Select
                            className="lg:w-48 md:w-32"
                            placeholder="Select Sync Mode"
                            options={["AUTOMATIC", "MANUAL"]}
                            textGetter={(mode) => mode}
                            valueGetter={(mode) => mode}
                            value={configSettings?.syncMode || "AUTOMATIC"}
                            onChange={(e) =>
                              setConfigSettings((prevSettings) => {
                                if (!prevSettings) return prevSettings;
                                return {
                                  ...prevSettings,
                                  syncMode: e.target.value as
                                    | "AUTOMATIC"
                                    | "MANUAL",
                                };
                              })
                            }
                          />
                        </Box>
                      </HStack>
                      <HStack className="justify-between gap-4 p-2">
                        <Box className="flex items-center justify-between w-full">
                          <p className="mt-4 mr-2 text-sm font-medium form-label">
                            Auto Transfer to Personal Email:
                          </p>
                          <Select
                            value={
                              configSettings?.autoTransferToPersonalEmail
                                ? "Yes"
                                : "No"
                            }
                            options={["Yes", "No"]}
                            onChange={(e) =>
                              setConfigSettings((prevSettings) => {
                                if (!prevSettings) return prevSettings;
                                return {
                                  ...prevSettings,
                                  autoTransferToPersonalEmail:
                                    e.target.value === "Yes",
                                };
                              })
                            }
                            className="p-2 border rounded lg:w-48 md:w-32"
                          />
                        </Box>
                      </HStack>
                      <HStack className="p-2">
                        <Box className="flex items-center justify-between w-full">
                          <p className="mt-4 mr-2 text-sm font-medium form-label">
                            Valuation for Grant:
                          </p>
                          <Select
                            value={configSettings?.valuationForGrant}
                            options={valuations.map((valuation) => valuation)}
                            textGetter={(valuation) =>
                              `${valuation.name} (${valuation.pps})`
                            }
                            valueGetter={(valuation) => valuation.id}
                            onChange={(e) =>
                              setConfigSettings((prevSettings) => {
                                if (!prevSettings) return prevSettings;
                                return {
                                  ...prevSettings,
                                  valuationForGrant: e.target.value,
                                };
                              })
                            }
                            className="p-2 border rounded lg:w-48 md:w-32"
                          />
                        </Box>
                      </HStack>
                    </Box>
                  )}
                  {configDetails.masterDataType === "GRANT" && (
                    <Box className="rounded-lg ">
                      {planDataFetched && grantsDataFetched && (
                        <ConfigForm
                          initialData={newConfigSettings || {}}
                          onClose={() => setConfigId("")}
                          isNewConfig={false}
                          isSyncEnabled={isSyncEnabled}
                        />
                      )}
                    </Box>
                  )}
                  {configDetails.masterDataType !== "GRANT" && (
                    <HStack className="justify-between gap-4">
                      <ButtonPrimary1 onClick={() => setConfigId("")}>
                        Cancel
                      </ButtonPrimary1>
                      <ButtonPrimary
                        loading={isDataSaving}
                        onClick={saveSettings}
                      >
                        Save
                      </ButtonPrimary>
                    </HStack>
                  )}
                </VStack>
              )}
            </>
          )}
        </VStack>
      </VStack>
      <Dialog open={newConfig.addingNewConfig || false} maxWidth="md">
        <div className="w-[900px] h-full">
          <ConfigForm
            onClose={() => setNewConfig({ addingNewConfig: false })}
            initialData={{ type: "GRANT" }} // Default values for a new config
            isNewConfig={true}
            isSyncEnabled={isSyncEnabled}
          />
        </div>
      </Dialog>
    </Box>
  );
}

export default EmployeeDatasourceSettings;
