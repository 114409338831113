export interface PlanLevelGrantTemplate {
  templateId: string;
  planName: string;
  planLevelGrantTemplate: string;
}

export interface ListOfPlansWithoutTemplate {
  templateId: string;
  planName: string;
  planLevelGrantTemplate?: string;
}

export interface GrantLetterTemplate {
  templateId?: string;
  planName: string;
  esopPlanId?: string;
  s3Path?: string;
  entitityType?: string;
  isPrivate: boolean;
  isChanged: boolean;
  isDeleted: boolean;
  isAdded: boolean;
  planLevelGrantTemplate?: string;
  isPlatFormDefault?: boolean;
}

export interface SiteSettingsRes {
  showPPSToEmployees?: boolean;
  emailsToBeSent: boolean;
  approverMailToAdmin: boolean;
  faqForEmployee?: boolean;
  communicationEmailId?: string;
  grantSignature?: string;
  planLevelGrantTemplate: PlanLevelGrantTemplate[];
  listOfPlansWithoutTemplates: ListOfPlansWithoutTemplate[];
  grantLetterTemplates: GrantLetterTemplate[];
  ppsForEmployee?: number;
  acceptanceNumber?: number;
  senderNameForEmail?: string;
  isEsignEnabled: boolean;
  showEmpPortalValuesForecast: boolean;
  overrideGrantLetter: boolean;
  isAttachmentEnabled: boolean;
  isEnabledTwoFactorAuthentication: boolean;
  reminderEmailsToBeSent: boolean;
  reminderEmailsStartFrom?: number;
  reminderEmailsInterval?: number;
  employeeInactivityLogout: boolean;
  shouldEmployeeBeIntimidated: boolean;
  isDocuSignEnabled: boolean;
  isDigioEnabled: boolean;
  signerDetails: SignerDetails[];
  signerDetailsDtos?: SignerDetails[];
  showPlanToEmployees?: boolean;
  sendEmailsToEmployees: boolean;
  sendGrantEmailsToEmployees: boolean;
  sendVestingEmailsToEmployees: boolean;
  sendBuyBackEmailsToEmployees: boolean;
  sendGrantOfferedEmailsToEmployees: boolean;
  sendGrantWithdrawEmailsToEmployees: boolean;
  sendGrantReminderEmailsToEmployees: boolean;
  sendVestingCompleteEmailsToEmployees: boolean;
  sendBuyBackOfferedEmailsToEmployees: boolean;
  sendBuyBackClosedEmailsToEmployees: boolean;
  sendBuyBackReminderEmailsToEmployees: boolean;
  sendEmailsToAdmin: boolean;
  sendGrantEmailsToAdmin: boolean;
  sendPlanEmailsToAdmin: boolean;
  sendTransactionEmailsToAdmin: boolean;
  sendReminderEmailsToAdmin: boolean;
  employeeSignatureForGrant: boolean;
  requestApproverSignature: boolean;
  isNomineeMandatory?: boolean;
}

export interface SignerDetails {
  id?: number;
  name: string;
  email: string;
  userId?: number;
  signerType?: SignerType;
  toBeAdded?: boolean;
  toBeUpdated?: boolean;
  toBeDeleted?: boolean;
}

export type SignerType = "OPTIONS";

export interface TemplateContent {
  defaultEmailBodyFile?: string;
  emailBodyFile?: string;
  emailSubject?: string;
  emailTemplateName?: string;
  emailTemplateType?: string;
}

export interface CompanyProfileDetails {
  id: string;
  name: string;
  cin: string;
  companyCurrency: string;
  dateOfIncorporation: string;
  address: string;
  email: string;
  companyLogoPath: string;
  operatingAs: string;
  nic: string;
  typeOfTheCompany?: string;
  stockSymbol?: string;
}

export type EmployeePortalSetting = {
  isNomineeMandatory: boolean;
  showPPSToEmployees: boolean;
  showAtGrantPPSToEmployees: boolean;
  showEmpPortalValuesForecast: boolean;
  showPlanToEmployees: boolean;
  mfaMandatoryForEmployee: boolean;
  enableGrantLetterDownload: boolean;
  showEquivalentShares: boolean;
  showFractionalPartOfEquivalentShares: boolean;
  authMethods: string[];
  generateCredentialsViaLink: boolean;
};
export type EmailSetting = {
  communicationEmailId: string;
  senderNameForEmail: string;
  reminderEmailsToBeSent: boolean;
  reminderEmailsInterval: number;
  reminderEmailsStartFrom: number;
  isAttachmentEnabled: boolean;
  employeeInactivityLogout: boolean;
  approverMailToAdmin: boolean;
  emailsToBeSent: boolean;
  sendEmailsToEmployees: boolean;
  sendGrantEmailsToEmployees: boolean;
  sendVestingEmailsToEmployees: boolean;
  sendBuyBackEmailsToEmployees: boolean;
  sendGrantOfferedEmailsToEmployees: boolean;
  sendGrantWithdrawEmailsToEmployees: boolean;
  sendGrantReminderEmailsToEmployees: boolean;
  sendVestingCompleteEmailsToEmployees: boolean;
  sendBuyBackOfferedEmailsToEmployees: boolean;
  sendBuyBackClosedEmailsToEmployees: boolean;
  sendForfeitureLeterToEmployees: boolean;
  sendBuyBackReminderEmailsToEmployees: boolean;
  sendEmailsToAdmin: boolean;
  sendGrantEmailsToAdmin: boolean;
  sendPlanEmailsToAdmin: boolean;
  sendTransactionEmailsToAdmin: boolean;
  sendReminderEmailsToAdmin: boolean;
  // employeeSignatureForGrant: boolean;
  sendMonthlyStatement?: boolean;
  emailTemplateCC?: any;
  sendCCEmailsToAdmins?: boolean;
  sendResignationReminderEmailsToAdmin: boolean;
  sendExerciseReminderEmailsToEmployees: boolean;
  exerciseReminderDays: number;
  employeeExerciseReminderIntervalEmails: number;
};

export type GrantLetterSetting = {
  acceptanceNumber: number;
  employeeSignatureForGrant: boolean;
  requestApproverSignature: boolean;
  overrideGrantLetter: boolean;
  grantLetterTemplates: GrantLetterTemplate[];
  authorizedSignature: string | undefined;
  dateFormat?: string;
  dateForLapsing?: string;
  autoPauseVestingOnResignation?: boolean;
};

export type ESignSetting = {
  isEsignEnabled: boolean;
  isDocuSignEnabled: boolean;
  signerDetails: SignerDetails[];
  signerDetailsDtos?: SignerDetails[];
  isDigioEnabled: boolean;
  useDigioElectronic?: boolean;
  useDigioAadhar?: boolean;
};

export type ListOfEmailTemplates = {
  companyId?: string;
  id: string;
  templateName: string;
  templateType: string;
};

export interface EmailTemplate {
  content?: string;
  footer?: string;
  header?: string;
  subject?: string;
}

export interface EmailTemplateSaveObject {
  emailTemplateBody: string;
  templateId: string;
  isEdited?: boolean;
}
export type EmailCCSettings = {
  sendCCEmailsToAdmins: boolean;
  emailTemplateCC?: { [k: string]: string[] };
};

export const AuthMethods: { [key: string]: string } = {
  "Password Based": "passwordBased",
  "Sign in with Google": "google",
};
