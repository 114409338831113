import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useState } from "react";
import {
  Box,
  ButtonPrimary,
  ButtonSecondary,
  Error,
  getCompanyName,
  HStack,
  VStack,
} from "../../components/utils";

import { generateSH6Report } from "../../api/Esop";
import { Input, Label } from "../../components/shared/InputField";
import { MultiSelect, OptionTypes } from "../../components/shared/MultiSelect";
import { useEsopPlans } from "../../queries";
import { PlanState, SH6ReportReq } from "../../types/EsopPlan";
import { ReportDto } from "../../types/Grant";
import { formatDate } from "../../utils/date";
import {
  convertBase64ToBlob,
  downloadBlobObject,
} from "../../utils/DownloadFile";

function SH6ReportDialog({
  onClose = () => {},
}: Readonly<{ onClose: () => void }>) {
  const { data: _plans } = useEsopPlans();
  const plans = _plans || [];
  const planOptions: OptionTypes[] = [];
  const [shouldPiiBeMasked, setShouldPiiBeMasked] = useState(false);

  const ids: string[] = [];
  plans
    .filter((p) => p.esopPlanState === PlanState.ACTIVE)
    .forEach((plan) => {
      planOptions.push({ value: plan.planName });
      ids.push(plan.esopPlanId);
    });

  const initialValues: SH6ReportReq = {
    date: formatDate(new Date().toUTCString()),
    planIds: ids,
    maskPiiData: false,
  };
  const validationSchema = Yup.object({
    date: Yup.date().required("required"),
    planIds: Yup.array()
      .test("notEmpty", "Select a plan", (value) => {
        if (value && value.length > 0) {
          return true;
        }
        return false;
      })
      .required("plan required"),
  });

  const fileName = `SH-6 Report_${getCompanyName()}`;

  async function downloadReport(sh6Report: ReportDto) {
    onClose();
    const blob = convertBase64ToBlob(sh6Report.base64File, sh6Report.fileType);
    downloadBlobObject(await blob, fileName);
  }
  let isLoading = false;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      values.maskPiiData = shouldPiiBeMasked;
      if (values.planIds.length > 0) {
        isLoading = true;
        generateSH6Report(values)
          .then((res) => {
            isLoading = false;
            downloadReport(res.data);
          })
          .catch(() => {
            toast("Something Went Wrong!", { type: "error", autoClose: 2000 });
            isLoading = false;
          });
      }
    },
  });
  return (
    <VStack className=" min-h-min">
      <div className="p-4 text-lg font-medium border-b ">
        <h6 className="flex justify-between">
          Generating SH-6
          <span className="cursor-pointer" onClick={() => onClose()}>
            X
          </span>
        </h6>
      </div>
      <VStack className="gap-2">
        <VStack className="w-full max-w-xl p-4">
          <Label> Plans</Label>
          <MultiSelect
            _className=""
            multiple={true}
            placeholder={"--Select plans--"}
            options={planOptions}
            handleSelection={(e) => {
              const planIds = plans
                .filter(
                  (p) =>
                    e.includes(p.planName) &&
                    p.esopPlanState === PlanState.ACTIVE
                )
                .map((p) => p.esopPlanId);
              formik.setFieldValue("planIds", planIds);
            }}
            optionValues={plans
              .filter((p) => formik.values.planIds.includes(p.esopPlanId))
              .map((p) => p.planName)}
          />
          {formik.errors.planIds && (
            <Error text={formik.errors.planIds.toString()} />
          )}
        </VStack>
        <HStack className="items-center justify-start gap-12 px-6">
          <Label className="text-sm font-normal">To Date:</Label>
          <Box>
            <Input
              type="date"
              value={formik.values.date}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                if (!e.target.value) return;
                formik.setFieldValue("date", e.target.value);
              }}
            />
          </Box>
        </HStack>
        <HStack className="justify-end gap-2 px-4 mt-7">
          <input
            type="checkbox"
            checked={shouldPiiBeMasked}
            onChange={() => setShouldPiiBeMasked(!shouldPiiBeMasked)}
            className="text-white accent-orange-501"
            name=""
            id=""
          />
          <span>Hide all Personally Identifiable Information</span>
        </HStack>
        <HStack className="justify-end m-3">
          <p className="italic">Note: The date filters are inclusive </p>
        </HStack>
        <HStack className="justify-between px-4 py-4">
          <ButtonSecondary
            onClick={() => onClose()}
            className="text-gray-400 bg-slate-50"
          >
            Cancel
          </ButtonSecondary>
          <div className="flex items-center">
            <ButtonPrimary
              disabled={isLoading}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Generate
            </ButtonPrimary>
            <div>{isLoading && <CircularProgress size={32} />}</div>
          </div>
        </HStack>
      </VStack>
    </VStack>
  );
}
export default SH6ReportDialog;
