import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import {
  useEsopPlans,
  useGrants,
  useEmployees,
  useUpdateExportSettings,
  useSaveNewExportConfig,
} from "../../queries";
import {
  Box,
  ButtonPrimary,
  ButtonPrimary1,
  ButtonSecondary,
  HStack,
  VStack,
} from "../../components/utils";
import { Input, Label } from "../../components/shared/InputField";
import { MultiSelectV2 } from "../../components/shared/MultiSelectV2";
import { NewConfigSettings } from "../../types/ExportExcelEmployee";
import { GrantState } from "../../types/common";
import { EmployementStatus } from "../../types/Employee";

interface ConfigFormProps {
  initialData: NewConfigSettings;
  onClose: () => void;
  isNewConfig: boolean;
  isSyncEnabled: boolean;
}

const ConfigForm: React.FC<ConfigFormProps> = ({
  initialData,
  onClose,
  isNewConfig,
  isSyncEnabled,
}) => {
  const { data: planData } = useEsopPlans();
  const { data: grantsData } = useGrants();
  const { data: employeeData } = useEmployees();
  const { mutate: editMasterConfig } = useUpdateExportSettings();
  const { mutate: createConfig } = useSaveNewExportConfig();
  const [isSaving, setIsSaving] = useState(false);

  const [configName, setConfigName] = useState(initialData.name || "");
  const [exportType, setExportType] = useState(initialData.type || "GRANT");
  const [planFilter, setPlanFilter] = useState<string[]>(
    initialData.planFilter?.planId?.include || []
  );
  const [grantTypeFilter, setGrantTypeFilter] = useState<string[]>(
    initialData.grantFilter?.grantType?.include || []
  );
  const [employeeTypeFilter, setEmployeeTypeFilter] = useState<string[]>(
    initialData.employeeFilter?.employMentType?.include || []
  );
  const [grantStateFilter, setGrantStateFilter] = useState<string[]>(
    initialData.grantFilter?.grantState?.include || []
  );
  const [apiKey, setApiKey] = useState(
    initialData.credentialData?.apiKey || ""
  );
  const [employeeStateFilter, setEmployeeStateFilter] = useState<string[]>(
    initialData.employeeFilter?.employmentStatus?.include || []
  );
  const [datasetKey, setDatasetKey] = useState(
    initialData.credentialData?.datasetKey || ""
  );
  const availableGrantTypes = useMemo(() => {
    const filteredGrants = grantsData?.filter((grant) =>
      planFilter.includes(grant.planid)
    );
    const grantTypes = new Set();
    filteredGrants?.forEach((grant) => grantTypes.add(grant.grantType));
    return Array.from(grantTypes).map((option) => ({
      value: option as string,
    }));
  }, [grantsData, planFilter]);
  const availableGrantStates = [
    GrantState.CREATED,
    GrantState.FOR_APPROVAL,
    GrantState.APPROVED,
    GrantState.OFFERED,
    GrantState.GRANTED,
    GrantState.WITHDRAW,
    GrantState.NOT_APPROVED,
  ].map((state) => ({ value: state }));
  const availableEmployeeStates = [
    EmployementStatus.EMPLOYED,
    EmployementStatus.RESIGNED,
    EmployementStatus.PENDING,
  ].map((state) => ({ value: state }));
  const generateRandomString = (length: number) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };
  useEffect(() => {
    if (isNewConfig) {
      setApiKey(generateRandomString(16));
      setDatasetKey(generateRandomString(32));
    }
  }, [isNewConfig]);

  useEffect(() => {
    if (isNewConfig) {
      return;
    }
    setConfigName(initialData.name || "");
    setExportType(initialData.type || "GRANT");
    setPlanFilter(initialData.planFilter?.planId?.include || []);
    setGrantTypeFilter(initialData.grantFilter?.grantType?.include || []);
    setEmployeeTypeFilter(
      initialData.employeeFilter?.employMentType?.include || []
    );
    setApiKey(initialData.credentialData?.apiKey || "");
    setEmployeeStateFilter(
      initialData.employeeFilter?.employmentStatus?.include || []
    );
    setGrantStateFilter(initialData.grantFilter?.grantState?.include || []);
    setDatasetKey(initialData.credentialData?.datasetKey || "");
  }, [initialData]);

  const planOptions = useMemo(
    () => planData?.map((plan) => ({ value: plan.planName })) || [],
    [planData]
  );
  const grantOptions = useMemo(() => {
    const options = new Set();
    const filteredGrants = grantsData?.filter((grant) =>
      planFilter.includes(grant.planid)
    );
    filteredGrants?.forEach((grant) => options.add(grant.grantType));
    return Array.from(options).map((option) => ({ value: option as string }));
  }, [grantsData, planFilter]);
  const employeeOptions = useMemo(() => {
    const options = new Set();
    employeeData?.forEach((employee) => options.add(employee.employmentType));
    return Array.from(options).map((option) => ({ value: option as string }));
  }, [employeeData]);
  const handlePlanFilter = (action: any) => {
    const filteredPlans = planData?.filter((plan) =>
      action.includes(plan.planName)
    );
    setPlanFilter(filteredPlans?.map((plan) => plan.esopPlanId) || []);
  };

  const selectedPlanNames = useMemo(
    () =>
      planData
        ?.filter((plan) => planFilter.includes(plan.esopPlanId))
        .map((plan) => plan.planName) || [],
    [planData, planFilter]
  );
  const selectedGrantTypes = useMemo(
    () => [...new Set(grantTypeFilter)],
    [grantTypeFilter]
  );
  const selectedEmployeeTypes = useMemo(
    () => [...new Set(employeeTypeFilter)],
    [employeeTypeFilter]
  );
  const selectedEmployeeStates = useMemo(
    () => [...new Set(employeeStateFilter)],
    [employeeStateFilter]
  );
  const selectedGrantStates = useMemo(
    () => [...new Set(grantStateFilter)],
    [grantStateFilter]
  );

  const copyToClipboard = (key: "Api Key" | "Dataset Key") => {
    navigator.clipboard.writeText(key === "Api Key" ? apiKey : datasetKey);
    toast(`${key} copied to clipboard`, { type: "success", autoClose: 2000 });
  };

  const handleSave = () => {
    setIsSaving(true);
    if (
      !configName ||
      !planFilter.length ||
      !grantTypeFilter.length ||
      !employeeTypeFilter.length
    ) {
      setIsSaving(false);
      toast("Please fill all the fields", { type: "error", autoClose: 2000 });
      return;
    }
    const baseData = {
      name: configName,
      type: exportType,
      enabled: isSyncEnabled,
      planFilter: {
        planId: {
          include: planFilter,
          exclude: [],
        },
      },
      grantFilter: {
        grantType: {
          include: grantTypeFilter,
          exclude: [],
        },
        grantState: {
          include: grantStateFilter,
          exclude: [],
        },
      },
      employeeFilter: {
        employMentType: {
          include: employeeTypeFilter,
          exclude: [],
        },
        employmentStatus: {
          include: employeeStateFilter,
          exclude: [],
        },
      },
    };

    if (isNewConfig) {
      const dataToSend = {
        ...baseData,
        addingNewConfig: true,
        credentialData: {
          apiKey,
          datasetKey,
        },
      };
      createConfig(dataToSend, {
        onSuccess: () => {
          toast("Config created successfully", {
            type: "success",
            autoClose: 2000,
          });
          setIsSaving(false);
          onClose();
        },
        onError: (error) => {
          setIsSaving(false);
          toast("Error creating config", { type: "error", autoClose: 2000 });
        },
      });
    } else {
      const updateBody = {
        ...baseData,
        configId: initialData.configId,
      };
      editMasterConfig(updateBody, {
        onSuccess: () => {
          setIsSaving(false);
          toast("Config edited successfully", {
            type: "success",
            autoClose: 2000,
          });
          onClose();
        },
        onError: (error) => {
          setIsSaving(false);
          toast("Error editing config", { type: "error", autoClose: 2000 });
        },
      });
    }
  };
  return (
    <>
      {isNewConfig && (
        <Box className="px-10 text-lg font-medium border-b py-7">
          <h6 className="flex justify-between">
            {isNewConfig ? "Create New Config" : "Edit Config"}
            <span onClick={() => onClose()} className="cursor-pointer">
              X
            </span>
          </h6>
        </Box>
      )}
      <Box className="gap-4 p-8 bg-white rounded-lg shadow">
        <HStack>
          <Box className="flex-1 w-full">
            <Label className="text-sm font-normal">Config Name</Label>
            <Input
              value={configName}
              onChange={(e) => setConfigName(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </Box>
        </HStack>

        <HStack className="justify-between flex-1 gap-8 pt-4">
          <Box className="w-full">
            <Label className="text-sm font-normal">Plan Filter</Label>
            <MultiSelectV2
              handleSelection={handlePlanFilter}
              multiple={true}
              optionValues={selectedPlanNames}
              placeholder="-- Select Plan(s) --"
              options={planOptions}
            />
          </Box>
        </HStack>
        <HStack className="justify-between flex-1 gap-8 pt-4">
          <Box className="w-1/2 mt-4">
            <Label className="text-sm font-normal">
              Employment Type Filter
            </Label>
            <MultiSelectV2
              handleSelection={setEmployeeTypeFilter}
              multiple={true}
              optionValues={selectedEmployeeTypes}
              placeholder="-- Select Employee Type(s) --"
              options={employeeOptions}
            />
          </Box>
          <Box className="w-1/2 mt-4">
            <Label className="text-sm font-normal">
              Employment Status Filter
            </Label>
            <MultiSelectV2
              handleSelection={setEmployeeStateFilter}
              multiple={true}
              optionValues={selectedEmployeeStates}
              placeholder="-- Select Employment State(s) --"
              options={availableEmployeeStates}
            />
          </Box>
        </HStack>
        <HStack className="justify-between flex-1 gap-8 pt-4">
          <Box className="w-1/2 mt-4">
            <Label className="text-sm font-normal">Grant Type Filter</Label>
            <MultiSelectV2
              handleSelection={setGrantTypeFilter}
              multiple={true}
              optionValues={selectedGrantTypes}
              placeholder="-- Select Grant Type(s) --"
              options={availableGrantTypes}
            />
          </Box>
          <Box className="w-1/2 mt-4">
            <Label className="text-sm font-normal">Grant Status Filter</Label>
            <MultiSelectV2
              handleSelection={setGrantStateFilter}
              multiple={true}
              optionValues={selectedGrantStates}
              placeholder="-- Select Grant State(s) --"
              options={availableGrantStates}
            />
          </Box>
        </HStack>
        <HStack className="justify-between gap-8 pt-4">
          <Box className="w-1/2">
            <Label className="text-sm font-normal">Api Key</Label>
            <HStack className="justify-between h-12 cursor-not-allowed form-input">
              {apiKey}
              <VStack
                className="justify-center cursor-pointer"
                onClick={() => copyToClipboard("Api Key")}
              >
                <Icon
                  icon="akar-icons:copy"
                  className="justify-center text-center align-middle hover:text-orange-501"
                  width={24}
                  height={24}
                ></Icon>
              </VStack>
            </HStack>
          </Box>
          <Box className="w-1/2">
            <Label className="text-sm font-normal">Dataset Key</Label>
            <HStack className="justify-between h-12 cursor-not-allowed form-input">
              {datasetKey}
              <VStack
                className="justify-center cursor-pointer"
                onClick={() => copyToClipboard("Dataset Key")}
              >
                <Icon
                  icon="akar-icons:copy"
                  className="justify-center text-center align-middle hover:text-orange-501"
                  width={24}
                  height={24}
                ></Icon>
              </VStack>
            </HStack>
          </Box>
        </HStack>
        <div className="flex justify-between pt-8">
          <ButtonSecondary
            className="text-gray-400 bg-slate-50"
            onClick={onClose}
          >
            Cancel
          </ButtonSecondary>
          <ButtonPrimary loading={isSaving} onClick={handleSave}>
            {isNewConfig ? "Create" : "Save"}
          </ButtonPrimary>
        </div>
      </Box>
    </>
  );
};

export default ConfigForm;
