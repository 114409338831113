import { Icon } from "@iconify/react";
import { Dialog, DialogProps } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useRevertResignation } from "../../queries/employees";
import {
  Employee,
  EmployeeCredentialGeneration,
  RevertResignation,
} from "../../types/Employee";
import { ButtonPrimary, ButtonSecondary, HStack, VStack } from "../utils";
import { CheckBoxWithLabel } from "./InputField";

export type RevertResignationTypes = {
  revertResignation: RevertResignation;
  onSecondaryAction: () => void;
} & DialogProps;

function RevertResignationDialog({
  revertResignation,
  onSecondaryAction,
  ...dialogProps
}: RevertResignationTypes) {
  const { mutate: revertResignationCall, isLoading } = useRevertResignation();
  const [vestAsPerToday, setVestAsPerToday] = useState(
    revertResignation.vestAsPerToday
  );
  const [sendEmailForVestings, setSendEmailForVestings] = useState(false);
  const [resumeFutureVestings, setResumeFutureVestings] = useState(false);
  const [
    revertAccessBackToPrimaryEmailId,
    setRevertAccessBackToPrimaryEmailId,
  ] = useState(false);
  function revertResignations() {
    revertResignationCall(
      {
        ...revertResignation,
        vestAsPerToday,
        resumeFutureVestings,
        revertAccessBackToPrimaryEmailId,
        sendEmailForVestings,
      },
      {
        onSuccess: () => {
          toast("Resignation reverted successfully!", {
            type: "success",
            autoClose: 2000,
          });
          onSecondaryAction();
        },
        onError: () => {
          toast("Something went wrong", { type: "error", autoClose: 2000 });
        },
      }
    );
  }
  return (
    <Dialog {...dialogProps} maxWidth="lg">
      <VStack className="justify-between p-9 bg-white rounded h-[500px]">
        <VStack className="items-center justify-center gap-6 grow">
          {<Icon icon="ep:warning" color="#FFE99B" height={72} />}
        </VStack>
        <p className="items-center justify-center gap-4 font-medium text-center text-dark grow text-xs3">
          {"Are you sure you want to revert resignation?"}
        </p>
        <HStack className="gap-4 p-2 text-blue-800 bg-blue-100 rounded">
          <VStack>
            <div className="pt-0">
              <Icon
                icon="material-symbols:info-outline-rounded"
                height={24}
                width={24}
                className="w-full text-xs font-medium text-blue-800 rounded cursor-pointer"
              />
            </div>
          </VStack>
          <VStack>
            <p className="">{`${revertResignation.employeeIds.length} employees selected
            `}</p>{" "}
          </VStack>
        </HStack>
        <VStack className="justify-start gap-4 pt-4 grow">
          <CheckBoxWithLabel
            label={`Vest until today`}
            checked={vestAsPerToday}
            labelclassname={"ml-2 text-xs2 text-dark"}
            onChange={(e) => setVestAsPerToday(e.target.checked)}
          />
          <CheckBoxWithLabel
            label={`Send vesting notification emails`}
            checked={sendEmailForVestings}
            labelclassname={"ml-2 text-xs2 text-dark"}
            onChange={(e) => setSendEmailForVestings(e.target.checked)}
          />
          <CheckBoxWithLabel
            label={`Resume future vestings if paused`}
            checked={resumeFutureVestings}
            labelclassname={"ml-2 text-xs2 text-dark"}
            onChange={(e) => setResumeFutureVestings(e.target.checked)}
          />
          <CheckBoxWithLabel
            label={`Revert access to primary email if transferred to personal email`}
            checked={revertAccessBackToPrimaryEmailId}
            labelclassname={"ml-2 text-xs2 text-dark"}
            onChange={(e) =>
              setRevertAccessBackToPrimaryEmailId(e.target.checked)
            }
          />
        </VStack>
        <HStack className="justify-between pt-4 gap-9">
          <ButtonSecondary
            onClick={() => onSecondaryAction()}
            className="text-gray-400 bg-slate-50"
          >
            {"Cancel"}
          </ButtonSecondary>
          <ButtonPrimary
            onClick={() => revertResignations()}
            loading={isLoading}
          >
            {"Revert Resignation"}
          </ButtonPrimary>
        </HStack>
      </VStack>
    </Dialog>
  );
}

export default RevertResignationDialog;
