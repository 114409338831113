/* eslint-disable no-control-regex */
import { Icon } from "@iconify/react";
import Box from "@mui/material/Box";
import { getNames } from "country-list";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  AddEmployeeReq,
  EmployementStatus,
  EmployementType,
} from "../types/Employee";

import {
  ButtonPrimary,
  ButtonPrimary1,
  Error,
  HStack,
  VStack,
} from "../components/utils";

import { Input, Label, TextArea } from "../components/shared/InputField";
import { Select } from "../components/shared/Select";
import { useAddEmployee, useSubsidiaryCompanyNames } from "../queries";

import EsopModellingGrades from "../constants/EsopModellingGrades";
import { useError } from "../store/errorStore";
import convertToBase64 from "../utils/convertToBase64";
import { formatWithTimeZone } from "../utils/date";
import { removeAnsiEscapeCodes } from "../utils/string";
import { SubsidiaryCompanyDropDown } from "../types/EsopSubsidiaryCompany";

type AddOrEditEmployee = {
  mode?: "Edit" | "Add";
  data: any[];
  onClose: () => void;
};

const AddEmployee = (props: AddOrEditEmployee) => {
  const [imageURL, setImageURL] = useState("");
  const errorMessage = useError();
  const { mode, onClose } = props;

  const statusDropdown = [
    {
      description: "Employed",
      value: EmployementStatus.EMPLOYED,
    },
    {
      description: "Resigned",
      value: EmployementStatus.RESIGNED,
    },
  ];
  const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Employee name is required"),
    employeeDesignation: Yup.string().required(
      "Employee Designation is required"
    ),
    contactNumber: Yup.string().matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
      "Must be only digits"
    ),
    department: Yup.string().required("Department is required"),
    email: Yup.string().email().required("Valid Email is required"),
    residentialStatus: Yup.string().required("Country is required"),
    nationality: Yup.string().required("Nationality is required"),
    employeeIdentification: Yup.string().required("ID is required"),
    dateOfJoin: Yup.string().required("Joining Date is required"),
    employmentStatus: Yup.string().required(" Employment Status is required"),
    lastDay: Yup.string().when("employmentStatus", {
      is: EmployementStatus.RESIGNED,
      then: Yup.string().required("Resignation Date Required"),
    }),
  });

  const intialValues: AddEmployeeReq = {
    employeeName: "",
    employeeDesignation: "",
    address: "",
    contactNumber: "",
    department: "",
    email: "",
    manager: "",
    residentialStatus: "India",
    nationality: "India",
    legalName: "",
    employeeIdentification: "",
    dateOfJoin: "",
    grade: "",
    employmentStatus: EmployementStatus.EMPLOYED,
    company: "",
    notes: "",
    imageURL: "",
    employmentType: EmployementType.EMPLOYEE,
    pan: "",
    id: "",
    subsidiaryCompanyId: "",
  };

  const { mutate: addEmployee, isLoading } = useAddEmployee();
  const [subsidiaryNames, setSubsidiaryNames] = useState<
    SubsidiaryCompanyDropDown[]
  >([]);
  const { data: subsidiaryCompanyNamesList } = useSubsidiaryCompanyNames();
  useEffect(() => {
    if (subsidiaryCompanyNamesList && subsidiaryCompanyNamesList.length > 0) {
      setSubsidiaryNames([...subsidiaryCompanyNamesList]);
    }
  }, [subsidiaryCompanyNamesList]);

  const handleSubmit = (values: AddEmployeeReq) => {
    const employeeDto = { ...values };
    if (
      employeeDto.employmentStatus === EmployementStatus.RESIGNED &&
      employeeDto.lastDay !== undefined
    ) {
      employeeDto.lastDay = formatWithTimeZone(
        employeeDto.lastDay || "2001-1-1"
      );
    }
    employeeDto.dateOfJoin = formatWithTimeZone(employeeDto.dateOfJoin);
    employeeDto.legalName = employeeDto.employeeName;
    addEmployee(employeeDto, {
      onSuccess: () => {
        toast("Employee Added Successfully!", {
          type: "success",
          autoClose: 2000,
        });
        onClose();
      },
      onError: (err: any) => {
        errorMessage.setMessage(err.response.data.errorMessage);
        toast(removeAnsiEscapeCodes(err.response.data.errorMessage), {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  };

  async function handleEditImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e?.target?.files?.[0]) return;
    const file = e.target.files[0];
    const base64: string = (await convertToBase64(file)) as string;
    setImageURL(base64);
  }
  return (
    <>
      <Box className="px-10 text-lg font-medium border-b py-7">
        <h6 className="flex justify-between">
          {mode === "Edit" ? "Edit Option Holder" : "Add New Option Holder"}{" "}
          <span onClick={() => onClose()} className="cursor-pointer">
            X
          </span>{" "}
        </h6>
      </Box>
      <VStack className="w-full px-10 py-7 gap-9">
        <Formik
          initialValues={intialValues}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <>
              <Form>
                <VStack className="w-full gap-9">
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Option Holder Name
                      </Label>
                      <Input
                        type="text"
                        {...formik.getFieldProps("employeeName")}
                      />
                      {formik.touched.employeeName &&
                        formik.errors.employeeName && (
                          <Error text={formik.errors.employeeName} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Option Holder Type
                      </Label>
                      <Select
                        isPlaceHolderDisabled={true}
                        options={["EMPLOYEE", "CONSULTANT"]}
                        {...formik.getFieldProps("employmentType")}
                      />
                      {formik.touched.employmentType &&
                        formik.errors.employmentType && (
                          <Error text={formik.errors.employmentType} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Employee ID</Label>
                      <Input
                        type="text"
                        {...formik.getFieldProps("employeeIdentification")}
                      />
                      {formik.touched.employeeIdentification &&
                        formik.errors.employeeIdentification && (
                          <Error text={formik.errors.employeeIdentification} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Designation</Label>
                      <Input
                        type="text"
                        {...formik.getFieldProps("employeeDesignation")}
                      />
                      {formik.touched.employeeDesignation &&
                        formik.errors.employeeDesignation && (
                          <Error text={formik.errors.employeeDesignation} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Organization
                      </Label>
                      <Select
                        className={`${
                          subsidiaryNames.length === 0
                            ? "cursor-not-allowed"
                            : ""
                        }`}
                        disabled={subsidiaryNames.length === 0}
                        options={subsidiaryNames}
                        {...formik.getFieldProps("subsidiaryCompanyId")}
                        textGetter={(e) => e.name}
                        valueGetter={(e) => e.id}
                      />
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Date of Joining
                      </Label>
                      <Input
                        type="date"
                        {...formik.getFieldProps("dateOfJoin")}
                      />
                      {formik.touched.dateOfJoin &&
                        formik.errors.dateOfJoin && (
                          <Error text={formik.errors.dateOfJoin} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Email ID</Label>
                      <Input type="email" {...formik.getFieldProps("email")} />
                      {formik.touched.email && formik.errors.email && (
                        <Error text={formik.errors.email} />
                      )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Contact Number
                      </Label>
                      <Input
                        type="text"
                        {...formik.getFieldProps("contactNumber")}
                      />
                      {formik.touched.contactNumber &&
                        formik.errors.contactNumber && (
                          <Error text={formik.errors.contactNumber} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Department</Label>
                      <Input
                        {...formik.getFieldProps("department")}
                        type="text"
                      />
                      {formik.touched.department &&
                        formik.errors.department && (
                          <Error text={formik.errors.department} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Grade</Label>
                      <Select
                        options={EsopModellingGrades}
                        isPlaceHolderDisabled={true}
                        {...formik.getFieldProps("grade")}
                      />
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Country of Residence
                      </Label>
                      <Select
                        options={getNames().sort()}
                        isPlaceHolderDisabled={true}
                        {...formik.getFieldProps("residentialStatus")}
                      />
                      {formik.touched.residentialStatus &&
                        formik.errors.residentialStatus && (
                          <Error text={formik.errors.residentialStatus} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Nationality</Label>
                      <Select
                        options={getNames().sort()}
                        isPlaceHolderDisabled={true}
                        {...formik.getFieldProps("nationality")}
                      />
                      {formik.touched.nationality &&
                        formik.errors.nationality && (
                          <Error text={formik.errors.nationality} />
                        )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Employment Status
                      </Label>
                      <Select
                        className="capitalize"
                        options={statusDropdown}
                        textGetter={(option) => option?.description}
                        valueGetter={(value) => value?.value}
                        value={formik.values.employmentStatus}
                        isPlaceHolderDisabled={true}
                        onChange={(option) => {
                          formik.setFieldValue(
                            "employmentStatus",
                            statusDropdown.find(
                              (s) => s.value === option?.target.value
                            )?.value
                          );
                        }}
                      />
                      {formik.touched.employmentStatus &&
                        formik.errors.employmentStatus && (
                          <Error text={formik.errors.employmentStatus} />
                        )}
                    </div>
                    <div className="flex-1">
                      <Label className="text-sm font-normal">
                        Date of Resignation
                      </Label>
                      <Input
                        type="date"
                        disabled={
                          formik.values.employmentStatus ===
                          EmployementStatus.EMPLOYED
                        }
                        {...formik.getFieldProps("lastDay")}
                      />
                      {formik.touched.lastDay && formik.errors.lastDay && (
                        <Error text={formik.errors.lastDay} />
                      )}
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Address</Label>
                      <TextArea {...formik.getFieldProps("address")} />
                    </div>
                    <div className="flex flex-row justify-between flex-1">
                      <Box>
                        <Label className="text-sm font-normal">
                          Profile Photo
                        </Label>
                      </Box>
                      <Box className="">
                        {imageURL === "" ? (
                          <label className="px-2 py-2 rounded-lg cursor-pointer bg-slate-100">
                            <Input
                              type="file"
                              className="hidden"
                              accept="image/png, image/jpeg,image/jpg"
                              onChange={handleEditImage}
                            />
                            Upload Image
                          </label>
                        ) : (
                          <div className="relative flex flex-col justify-between">
                            <Label>
                              <input
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                className="hidden"
                                onChange={handleEditImage}
                              />
                              <Icon
                                icon={"eva:edit-outline"}
                                className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 hover:text-black"
                              />
                            </Label>
                            <img
                              className="right-0 block w-32 h-32 space-y-3 bg-gray-400 border-2 rounded-lg"
                              src={imageURL}
                              alt="avatar"
                            />
                            <Label>
                              <Icon
                                icon={"eva:close-fill"}
                                onClick={() => {
                                  setImageURL("");
                                }}
                                className="absolute z-10 w-6 h-6 text-gray-400 bg-gray-100 rounded-full cursor-pointer -right-2 -bottom-2 hover:text-black"
                              />
                            </Label>
                          </div>
                        )}
                      </Box>
                    </div>
                  </HStack>
                  <HStack className="gap-8 ">
                    <div className="flex-1">
                      <Label className="text-sm font-normal">Notes</Label>
                      <TextArea {...formik.getFieldProps("notes")} />
                    </div>
                    <div className="flex-1"></div>
                  </HStack>
                  <HStack className="justify-between">
                    <ButtonPrimary1
                      type="reset"
                      className="text-red-500"
                      onClick={onClose}
                    >
                      {" "}
                      Back
                    </ButtonPrimary1>
                    <ButtonPrimary type="submit" loading={isLoading}>
                      Create Option Holder
                    </ButtonPrimary>
                  </HStack>
                </VStack>
              </Form>
            </>
          )}
        </Formik>
      </VStack>
    </>
  );
};

export default AddEmployee;
